import { GAME_DICTIONARY } from '../../constants/game-dictionary'
import { useEffect, useState } from 'react'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  wordToDefine: string
}

export const WordDefinitionModal = ({
  isOpen,
  handleClose,
  wordToDefine
}: Props) => {
  const [wordDefinition, setWordDefinition] = useState({
    id: 0,
    kategoria: "",
    fjala: "",
    favourite: false,
    pershkrimet: [""]
  });
  useEffect(() => {
    const guessDefinition = GAME_DICTIONARY
      .filter(word => word.fjala === wordToDefine)[0];
    setWordDefinition(guessDefinition);
  }, [wordToDefine]);
  return (
    <BaseModal title={`Përshkrimi i fjalës ${wordToDefine}`} isOpen={isOpen} handleClose={handleClose}>
      <div className="mt-2 flex flex-col divide-y">
        {wordDefinition &&
          <div className="mt-5 sm:mt-6 columns-1 dark:text-white">
            <p>{wordDefinition?.fjala}</p>
            {wordDefinition?.pershkrimet.map((description: string, index: number) =>
              <p dangerouslySetInnerHTML={{ __html: `${index + 1}. ${description}` }} key={index}>
              </p>)}
          </div>}
      </div>
    </BaseModal>
  )
}
