export function getLocaleSplit(userText: string) {
    var userWord = userText.split('');
    let alphabetArray = [];
    for (let i = 0; i < userWord.length; i++) {
        let character = userWord[i];
        if (character === 'T' && userWord[i + 1] === "H") {
            i++;
            alphabetArray.push("TH");
          }
          else if (character === 'S' && userWord[i + 1] === "H") {
            i++;
            alphabetArray.push("SH");
          } else if (character === 'X' && userWord[i + 1] === "H") {
            i++;
            alphabetArray.push("XH");
          } else if (character === 'G' && userWord[i + 1] === "J") {
            i++;
            alphabetArray.push("GJ");
          } else if (character === 'Z' && userWord[i + 1] === "H") {
            i++;
            alphabetArray.push("ZH");
          } else if (character === 'R' && userWord[i + 1] === "R") {
            i++;
            alphabetArray.push("RR");
          } else if (character === 'L' && userWord[i + 1] === "L") {
            i++;
            alphabetArray.push("LL");
          } else if (character === 'D' && userWord[i + 1] === "H") {
            i++;
            alphabetArray.push("DH");
          } else if (character === 'N' && userWord[i + 1] === "J") {
            i++;
            alphabetArray.push("NJ");
          } else {
            alphabetArray.push(character);
          }
    }
    return alphabetArray;
  }

  function getLocaleLength(word: string) {
    return getLocaleSplit(word).length;
  }

  export default getLocaleLength;