export const GAME_TITLE = "fjalth.com"

export const WIN_MESSAGES = ['Shumë mirë!', 'Shkëlqyeshem!', 'E qëlluar!']
export const GAME_COPIED_MESSAGE = 'Rezultati është kopjuar në clipboard'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Numri i shkronjave të zgjedhura nuk mjafton'
export const WORD_NOT_FOUND_MESSAGE = 'Fjala që keni zgjedhur nuk gjendet në databazën tonë!'
export const HARD_MODE_ALERT_MESSAGE = 'Mënyra e vështirë e lojës mund të zgjedhet vetëm në fillim të lojës!'
export const HARD_MODE_DESCRIPTION =
  'Çdo shkronje e zbuluar duhet te perdoret ne hamendësimet e radhës'
export const HIGH_CONTRAST_MODE_DESCRIPTION = ''
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Fjala e kërkuar ishte ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Duhet të perdorësh ${guess} në pozitën ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Hamendësimi duhet të përmbajë ${letter}`
export const ENTER_TEXT = 'Vazhdo'
export const DELETE_TEXT = 'Fshij'
export const STATISTICS_TITLE = 'Statistikat'
export const GUESS_DISTRIBUTION_TEXT = 'Shpërndarja e hamendësimeve'
export const NEW_WORD_TEXT = 'Fjala e re në'
export const SHARE_TEXT = 'Kopjo rezultatin'
export const SHARE_FAILURE_TEXT =
  'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
export const MIGRATE_BUTTON_TEXT = 'Transfero rezultatet'
export const MIGRATE_DESCRIPTION_TEXT =
  'Kliko këtu për të transferuar statistikat (rezultatet) tua ne një paisje tjetër'
export const TOTAL_TRIES_TEXT = 'Totali i hamendësimeve'
export const SUCCESS_RATE_TEXT = 'Përqindja e suksesit'
export const CURRENT_STREAK_TEXT = 'Qëllime të sakta të njëpasnjëshme'
export const BEST_STREAK_TEXT = 'Më së shumti qëllime të sakta të njëpasnjëshme'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser."

export const DATEPICKER_TITLE = 'Zgjedh datën:'
export const DATEPICKER_CHOOSE_TEXT = 'Zgjedh diten e'
export const DATEPICKER_TODAY_TEXT = 'sotme'
export const ARCHIVE_GAMEDATE_TEXT = 'Loja e dates'
