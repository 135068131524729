export const WORDS = [
  "vjelë",
  "korrës",
  "shtupë",
  "halëz",
  "rrurëz",
  "lashtë",
  "lopar",
  "fijor",
  "tigër",
  "pëllua",
  "porse",
  "grivë",
  "pallua",
  "bajzë",
  "dritë",
  "thjesht",
  "hokej",
  "pleng",
  "shpullë",
  "nomad",
  "shqimth",
  "vijosh",
  "veshëz",
  "irizë",
  "paftë",
  "madem",
  "klimë",
  "shtënë",
  "sakat",
  "rrodhëz",
  "prijë",
  "vatan",
  "lenoj",
  "njohur",
  "zogëz",
  "lugës",
  "diarre",
  "dokthi",
  "asket",
  "rrudhët",
  "rryhem",
  "skerc",
  "kartë",
  "shenjtë",
  "marroj",
  "bijim",
  "punim",
  "legen",
  "pahit",
  "shpardh",
  "ligem",
  "burrec",
  "farosh",
  "namus",
  "çicërr",
  "llojor",
  "vorbë",
  "gosti",
  "sytar",
  "asllan",
  "lëçit",
  "varem",
  "bekoj",
  "kalesh",
  "hipet",
  "sinod",
  "syngji",
  "ecuri",
  "gjëmoj",
  "veshur",
  "gurrëz",
  "zilis",
  "sabah",
  "zbejt",
  "çakër",
  "shekull",
  "rrëshiq",
  "gërreç",
  "presë",
  "pafaj",
  "shukthi",
  "kohor",
  "postë",
  "koçek",
  "havlli",
  "dekor",
  "pushim",
  "dhiare",
  "puthur",
  "llupës",
  "cullak",
  "qukan",
  "këllas",
  "kamish",
  "diçka",
  "shtizë",
  "kepët",
  "rrahur",
  "njimtë",
  "llautë",
  "shulmë",
  "udhëzi",
  "resoj",
  "shagat",
  "shtrap",
  "tharës",
  "glasë",
  "suadhe",
  "tinar",
  "silic",
  "cullak",
  "filiç",
  "kërrlë",
  "lismë",
  "çashëm",
  "velëz",
  "shkund",
  "lumor",
  "dhiqel",
  "torbë",
  "sevap",
  "ndiej",
  "bukur",
  "zullap",
  "natën",
  "poemë",
  "samet",
  "çengi",
  "petull",
  "motje",
  "faqor",
  "shtuar",
  "rrëmih",
  "bizgë",
  "dëlir",
  "kinez",
  "balluk",
  "dhokan",
  "tenis",
  "veshok",
  "sahan",
  "sheqer",
  "ahore",
  "janar",
  "larak",
  "rrakat",
  "cungë",
  "gjëllij",
  "çapok",
  "llokmë",
  "veshuk",
  "agave",
  "pengë",
  "pasim",
  "mësoj",
  "tashëm",
  "copash",
  "fitim",
  "tuboj",
  "vakoj",
  "zhytar",
  "vakur",
  "shfryj",
  "meshoj",
  "efekt",
  "calik",
  "xixon",
  "patan",
  "thanec",
  "lakas",
  "garraç",
  "atlet",
  "çulzë",
  "esëllt",
  "reshur",
  "danez",
  "cekët",
  "bikër",
  "polak",
  "thikaç",
  "xhurdi",
  "regjur",
  "arsej",
  "bulgë",
  "xhufkë",
  "pemël",
  "athjez",
  "evari",
  "endëz",
  "mixan",
  "qesim",
  "spore",
  "dëshmi",
  "çilem",
  "veshës",
  "çikërr",
  "kurse",
  "kamje",
  "prime",
  "vigël",
  "kloun",
  "hamull",
  "beden",
  "palëz",
  "argon",
  "gëzoj",
  "zikeq",
  "ngoje",
  "dejçë",
  "flegë",
  "mbars",
  "dëboj",
  "zullum",
  "stelë",
  "sotme",
  "zhugull",
  "tutor",
  "bixhas",
  "ngredh",
  "çafër",
  "totem",
  "dyfek",
  "cubar",
  "ftujë",
  "vagon",
  "theron",
  "engjëll",
  "vator",
  "ezmer",
  "qafëz",
  "njëzim",
  "baltë",
  "lartë",
  "pinar",
  "dorje",
  "andaj",
  "bodec",
  "ligjor",
  "kauzë",
  "gulfë",
  "ditoj",
  "kuant",
  "ribëj",
  "tymek",
  "këshill",
  "tymos",
  "verem",
  "unazë",
  "karkë",
  "planë",
  "mjetë",
  "qukmë",
  "gjerdhe",
  "gërric",
  "ligsht",
  "gjelac",
  "fryjë",
  "hapur",
  "grimë",
  "hutoj",
  "thuket",
  "oqean",
  "fresk",
  "kolik",
  "fyshtë",
  "fulqi",
  "mreçë",
  "hutaq",
  "gjakoj",
  "mullëz",
  "shtrak",
  "harem",
  "harrok",
  "mënoj",
  "tabut",
  "kapje",
  "veçan",
  "qypës",
  "puthem",
  "ylefe",
  "qeros",
  "thekur",
  "bosti",
  "shtriq",
  "sfond",
  "kryer",
  "dukje",
  "eshkët",
  "xhibër",
  "britm",
  "puprri",
  "grurë",
  "ëmbël",
  "bandë",
  "grahe",
  "sekull",
  "shtiak",
  "kërrit",
  "pupërr",
  "sodom",
  "gomtë",
  "tufoj",
  "bëzaj",
  "peshoj",
  "rrokas",
  "shtjerr",
  "ndukë",
  "kukoj",
  "polem",
  "tufëz",
  "popull",
  "baule",
  "kuacë",
  "vogël",
  "prask",
  "shkozë",
  "tagjit",
  "zhytthi",
  "alpin",
  "bucem",
  "kepcë",
  "crogë",
  "fluor",
  "sixhim",
  "fletë",
  "mullac",
  "lojës",
  "fabul",
  "udhore",
  "diqysh",
  "lashta",
  "rrunëz",
  "tepri",
  "pupël",
  "duman",
  "peshli",
  "aktiv",
  "zhubël",
  "sharroj",
  "purthë",
  "çetoj",
  "shkesë",
  "buhar",
  "terak",
  "shushaq",
  "gufem",
  "lërim",
  "dyzash",
  "racor",
  "kaçak",
  "pelas",
  "eston",
  "bishtth",
  "lexim",
  "rrasët",
  "çerek",
  "llambë",
  "therje",
  "arrati",
  "zhëngë",
  "godas",
  "triko",
  "tremb",
  "sallam",
  "cimar",
  "imtim",
  "gjatas",
  "ujqer",
  "mugull",
  "thëllon",
  "lisën",
  "moçim",
  "kolës",
  "llashkë",
  "nëntë",
  "çapem",
  "vapor",
  "elegji",
  "larush",
  "zhargë",
  "kasher",
  "arsim",
  "picorr",
  "gjunjak",
  "masiv",
  "qethës",
  "rrihem",
  "qepur",
  "utopi",
  "lëmoj",
  "qafem",
  "gashtë",
  "hartë",
  "lahur",
  "gruzë",
  "ujerk",
  "burgji",
  "rrënim",
  "hordhi",
  "varrim",
  "xherah",
  "tigan",
  "mbrydh",
  "kapar",
  "rrobull",
  "petash",
  "radhor",
  "legen",
  "rralloj",
  "vizat",
  "agoni",
  "gurron",
  "faros",
  "madhem",
  "pasoj",
  "tamam",
  "njerith",
  "gjykoj",
  "palëz",
  "carac",
  "pacen",
  "mbles",
  "gjedhim",
  "ndjej",
  "milet",
  "telve",
  "zgrof",
  "hyjni",
  "gjezap",
  "shtroj",
  "rigash",
  "shoshar",
  "rumun",
  "dobët",
  "lugoj",
  "mijtë",
  "limëz",
  "llufit",
  "bresht",
  "mjeke",
  "cullak",
  "çilak",
  "linjit",
  "shiosë",
  "cengë",
  "dënes",
  "shytan",
  "ditar",
  "bathje",
  "muçit",
  "pinok",
  "matem",
  "shulthi",
  "delme",
  "hithër",
  "ardhur",
  "vjellë",
  "lapër",
  "rrëcok",
  "bokël",
  "zorrëz",
  "zhugëz",
  "rënës",
  "kredo",
  "ligaç",
  "krezm",
  "cokël",
  "patar",
  "debit",
  "llapan",
  "lijosh",
  "takat",
  "gjatje",
  "zonal",
  "dhogaç",
  "spikth",
  "kodër",
  "treni",
  "gërrqe",
  "trupë",
  "kuban",
  "shënim",
  "kllaçë",
  "harar",
  "kohem",
  "koshëz",
  "prekë",
  "çantë",
  "larmi",
  "gdhend",
  "humba",
  "shapkë",
  "murrash",
  "qasur",
  "rrethor",
  "fillak",
  "palmë",
  "endur",
  "toger",
  "huaza",
  "tëholl",
  "frenjë",
  "derrçe",
  "bythëz",
  "dhjamë",
  "qivur",
  "ftuar",
  "stavë",
  "qilar",
  "shotan",
  "bletë",
  "harraq",
  "çukit",
  "tutje",
  "cikël",
  "hazër",
  "bushtë",
  "sallon",
  "shkrep",
  "çelje",
  "xhelat",
  "brokë",
  "betoj",
  "xhiger",
  "detar",
  "bulon",
  "sonet",
  "kinse",
  "vishtë",
  "markë",
  "faqoll",
  "toskë",
  "çivit",
  "rrëzim",
  "gojac",
  "pirat",
  "ideal",
  "detar",
  "lende",
  "lidhur",
  "qerpi",
  "ibrik",
  "rrugës",
  "hojmë",
  "demek",
  "xhefkë",
  "çatall",
  "rrezgë",
  "lungë",
  "kësosh",
  "allçak",
  "zhakra",
  "çokëz",
  "pëllas",
  "gjylaç",
  "hirtë",
  "llamje",
  "sorrak",
  "koran",
  "aleat",
  "kikëz",
  "prerë",
  "lakeç",
  "helen",
  "sinor",
  "harrur",
  "qetim",
  "lerash",
  "qafak",
  "drejt",
  "barroj",
  "visar",
  "tallaz",
  "hebre",
  "çupit",
  "markë",
  "zvarrë",
  "capim",
  "shtëng",
  "lodër",
  "therës",
  "perçe",
  "pofkë",
  "pushkë",
  "birçe",
  "vrarë",
  "braci",
  "lutem",
  "topall",
  "otresh",
  "gazep",
  "mjerë",
  "shujtë",
  "faqosh",
  "stepë",
  "cikmë",
  "çatis",
  "kroat",
  "veton",
  "ojarë",
  "divit",
  "çimërr",
  "aksham",
  "zhavorr",
  "shofkë",
  "qilim",
  "germë",
  "burrni",
  "tunel",
  "çizme",
  "magjar",
  "marrak",
  "brinjë",
  "lodhje",
  "baror",
  "folme",
  "gjedhër",
  "kthjell",
  "sotëm",
  "damaz",
  "ruspë",
  "shijoj",
  "maçorr",
  "topçi",
  "citos",
  "falëz",
  "trung",
  "fitil",
  "vonoj",
  "këtej",
  "kurje",
  "labçe",
  "vejush",
  "veshak",
  "termë",
  "qarës",
  "gjymtë",
  "bojli",
  "pilot",
  "latoj",
  "yshmer",
  "luari",
  "brejë",
  "gargë",
  "hidhët",
  "ushtër",
  "indor",
  "munxë",
  "urithe",
  "qartë",
  "bambu",
  "dragë",
  "rinjoh",
  "zhulas",
  "fletë",
  "mesun",
  "gjembth",
  "kroat",
  "largu",
  "pincë",
  "cikas",
  "organ",
  "lashtë",
  "forum",
  "ajkoj",
  "fikët",
  "kërcu",
  "rukoj",
  "butoj",
  "çelës",
  "çalok",
  "bursë",
  "vjellë",
  "bigoj",
  "smalt",
  "shurrak",
  "rrënjos",
  "cangë",
  "çohet",
  "zdatk",
  "pauzë",
  "gjurmë",
  "hirraq",
  "myshnjë",
  "shumta",
  "artis",
  "shirje",
  "njashtu",
  "ngulm",
  "shkollë",
  "pafre",
  "borit",
  "kthesë",
  "çatall",
  "gjiriz",
  "etshoj",
  "tasuk",
  "biçak",
  "rezil",
  "hipur",
  "pirun",
  "kosit",
  "zhulat",
  "deist",
  "shkrif",
  "ndytë",
  "sipër",
  "mazut",
  "ëmbël",
  "nanaç",
  "zarbë",
  "xhumbë",
  "rrekem",
  "etshëm",
  "teste",
  "lakër",
  "kollit",
  "seroj",
  "tatoj",
  "xhelep",
  "gatër",
  "perrik",
  "thekës",
  "poste",
  "helen",
  "kredi",
  "pleks",
  "kopil",
  "cimak",
  "mujoj",
  "baron",
  "kullim",
  "atësi",
  "hinkë",
  "urryes",
  "kafshë",
  "bezdi",
  "folur",
  "hapët",
  "havan",
  "zhuzhet",
  "fener",
  "shuloj",
  "palëz",
  "trimth",
  "korbe",
  "mermë",
  "hijor",
  "mëzoj",
  "qefil",
  "kërci",
  "vegim",
  "gliko",
  "piloj",
  "trase",
  "bukthi",
  "retër",
  "amfib",
  "çikje",
  "errtas",
  "shkesë",
  "shtjell",
  "spirë",
  "syçkë",
  "endet",
  "cibun",
  "hafif",
  "gallof",
  "digjem",
  "mirëz",
  "bathëz",
  "prind",
  "teltë",
  "zorit",
  "prift",
  "virok",
  "votoj",
  "gjersa",
  "shitoj",
  "qepshe",
  "xhepash",
  "piskë",
  "valët",
  "hopthi",
  "kacit",
  "skofi",
  "bimsë",
  "zhelan",
  "karshi",
  "yrnek",
  "haliç",
  "batis",
  "tabor",
  "grazhd",
  "heqës",
  "rrezit",
  "gagaç",
  "dregë",
  "ligjoj",
  "rrjetë",
  "elitë",
  "revan",
  "bikme",
  "ficur",
  "urues",
  "dosëz",
  "xëgoj",
  "vrithe",
  "zebash",
  "rreptë",
  "prore",
  "gdhirë",
  "skili",
  "atllas",
  "shilte",
  "zarar",
  "imzot",
  "vemëz",
  "uthullt",
  "thirrmë",
  "amësi",
  "morrac",
  "buzuk",
  "thinjak",
  "rrotull",
  "xhanan",
  "shqyer",
  "gjëmoj",
  "zblok",
  "skrodh",
  "skemë",
  "ferrok",
  "krues",
  "malok",
  "xhavërr",
  "çalthi",
  "bëhem",
  "juntë",
  "furan",
  "ftues",
  "herdhe",
  "përdhe",
  "sedef",
  "bobol",
  "kudër",
  "grroçe",
  "atashe",
  "sykeq",
  "sinjal",
  "puroj",
  "gazoj",
  "vetull",
  "titan",
  "toskë",
  "duhur",
  "dhënës",
  "brumë",
  "utrin",
  "rrogoz",
  "gjynah",
  "ditar",
  "duroj",
  "abaci",
  "kukan",
  "vidul",
  "rytbe",
  "zgrop",
  "thurur",
  "shkulm",
  "cirkë",
  "lëkuq",
  "cekët",
  "vergji",
  "pegun",
  "qefin",
  "beztë",
  "thekët",
  "lagës",
  "talër",
  "festë",
  "doçkë",
  "urdhër",
  "praps",
  "ujëse",
  "qypthi",
  "regjur",
  "çnduk",
  "matur",
  "vetëm",
  "ënjtem",
  "rrethje",
  "buzor",
  "sharan",
  "pirës",
  "turli",
  "zhegër",
  "tarim",
  "marrës",
  "zhapik",
  "valor",
  "noter",
  "hurbë",
  "vërej",
  "joshës",
  "humai",
  "thërres",
  "fjord",
  "qenëz",
  "priqe",
  "mënik",
  "shpojë",
  "praqe",
  "molar",
  "cubel",
  "rripem",
  "amtar",
  "muros",
  "skupë",
  "hidër",
  "liqos",
  "ngroh",
  "ormis",
  "rrënjëz",
  "paerë",
  "pushoj",
  "vëllam",
  "amshim",
  "petël",
  "gurje",
  "bajat",
  "linjta",
  "hundë",
  "gjuhem",
  "safir",
  "kuckë",
  "tegel",
  "senat",
  "vesim",
  "deltë",
  "lutës",
  "koshiq",
  "urdhje",
  "shumës",
  "formë",
  "remtë",
  "ushtor",
  "belush",
  "araqe",
  "ndonjë",
  "lladik",
  "rimarr",
  "tafta",
  "mëngë",
  "sondë",
  "shkurre",
  "korrik",
  "atlas",
  "telis",
  "sheqëz",
  "zihet",
  "villem",
  "shqopë",
  "blacë",
  "urtoj",
  "vilat",
  "mirazh",
  "aktiv",
  "vakëf",
  "rrugaç",
  "fletë",
  "goruc",
  "bylme",
  "imshtë",
  "vaskë",
  "qurrak",
  "hasje",
  "pushem",
  "sërma",
  "gletë",
  "sustë",
  "larje",
  "truar",
  "patoz",
  "biraç",
  "fërroj",
  "spicë",
  "pipër",
  "rrotull",
  "jakëz",
  "bluaj",
  "pjekë",
  "vlesë",
  "ngjatë",
  "grremç",
  "kryqe",
  "pulpë",
  "kunjor",
  "shklluq",
  "curle",
  "poçar",
  "rrahoj",
  "duhmë",
  "çetash",
  "qafos",
  "pajoj",
  "berëz",
  "varrim",
  "lexoj",
  "çifçi",
  "roman",
  "ujerë",
  "fetish",
  "leros",
  "barrsë",
  "pohoj",
  "zgrip",
  "çilek",
  "garazh",
  "ënjtur",
  "gajgë",
  "ushtre",
  "vetiu",
  "mëzith",
  "bedel",
  "thuthuq",
  "zvarrë",
  "mohoj",
  "pykël",
  "shukos",
  "negër",
  "roshan",
  "prizë",
  "kapan",
  "cubni",
  "bozhor",
  "kotem",
  "ngase",
  "merak",
  "lënie",
  "rreshkë",
  "pilcë",
  "shkalle",
  "shushem",
  "epshor",
  "dhuroj",
  "terak",
  "qingje",
  "rrënjor",
  "beter",
  "ujëse",
  "mbarë",
  "gomim",
  "jetoj",
  "qesar",
  "oksid",
  "motit",
  "branë",
  "erëza",
  "fryzë",
  "faran",
  "tutem",
  "vagull",
  "gushas",
  "veshar",
  "rrëmoj",
  "dredhi",
  "këlysh",
  "squkë",
  "lyros",
  "bazal",
  "murriz",
  "buçet",
  "bërryl",
  "brymë",
  "citos",
  "survë",
  "buçkë",
  "kishar",
  "bakall",
  "sepal",
  "dyzoj",
  "dosar",
  "keltë",
  "ruhem",
  "xanxë",
  "balash",
  "rritje",
  "sakat",
  "krybe",
  "shitem",
  "qafor",
  "zbruj",
  "armor",
  "pëgëj",
  "pykëz",
  "cikërr",
  "boenë",
  "zebër",
  "pirrem",
  "rubak",
  "krizë",
  "arrnjet",
  "cemtë",
  "çekje",
  "istëm",
  "lugur",
  "kojkë",
  "dhënie",
  "bukur",
  "çadër",
  "pësoj",
  "afshon",
  "grillë",
  "poçar",
  "rrakoq",
  "shkabë",
  "qethur",
  "rrëzoj",
  "krevë",
  "zhyhem",
  "zuskë",
  "rëndë",
  "masat",
  "yshtës",
  "dyzet",
  "yshtës",
  "bretk",
  "barazh",
  "topçe",
  "petoj",
  "butak",
  "ligat",
  "prikë",
  "detal",
  "meltë",
  "kodosh",
  "debat",
  "mëmëz",
  "backë",
  "tuhaf",
  "çatma",
  "ligosh",
  "mollët",
  "falas",
  "fushor",
  "dogmë",
  "kecan",
  "dukat",
  "porrit",
  "ultas",
  "farkë",
  "paçka",
  "shtyrë",
  "tango",
  "pakem",
  "çahje",
  "therës",
  "liqen",
  "xhungë",
  "ngjicë",
  "çifte",
  "magmë",
  "presë",
  "çmpij",
  "dallgë",
  "vulos",
  "oshnik",
  "muhar",
  "pesësh",
  "dyjar",
  "lëkuq",
  "dhiatë",
  "shumor",
  "doras",
  "cucëll",
  "glinë",
  "varkë",
  "nimfe",
  "pipëz",
  "hupës",
  "çirrmë",
  "lëmar",
  "thirror",
  "hipik",
  "njolloj",
  "pasmë",
  "troke",
  "shpesë",
  "rigon",
  "qitap",
  "cigan",
  "huazi",
  "basen",
  "citem",
  "vigël",
  "pupas",
  "falur",
  "gjanës",
  "komit",
  "shfrim",
  "civil",
  "konop",
  "flaut",
  "sqimë",
  "shtesë",
  "epokë",
  "vogël",
  "varak",
  "dorës",
  "shtrim",
  "graso",
  "veqil",
  "çarje",
  "tallash",
  "mjerë",
  "bobël",
  "ardhme",
  "fosil",
  "gushit",
  "burras",
  "vidhez",
  "temin",
  "botim",
  "heqël",
  "mbodhi",
  "xhibal",
  "thithak",
  "shihet",
  "ortek",
  "rruzull",
  "tokem",
  "prapë",
  "gjethim",
  "barok",
  "çafkë",
  "ëmbël",
  "bukëz",
  "memec",
  "vrajë",
  "sterrë",
  "vjerdh",
  "fillthi",
  "tanoj",
  "shikim",
  "gjetull",
  "çapit",
  "llajkë",
  "akoma",
  "rruhem",
  "agjent",
  "vrasi",
  "katil",
  "qypje",
  "shkelm",
  "kurriz",
  "paqyll",
  "bërsi",
  "kallem",
  "faqos",
  "sakëz",
  "troke",
  "verak",
  "ojnak",
  "prerë",
  "mësim",
  "kombe",
  "hupët",
  "llonxhë",
  "banik",
  "ojtni",
  "afgan",
  "fëlliq",
  "mizon",
  "lëvarr",
  "kërdi",
  "pagim",
  "murgë",
  "romak",
  "polik",
  "bumbë",
  "mërej",
  "bufal",
  "tretë",
  "shpene",
  "garrit",
  "krekë",
  "barni",
  "djegë",
  "mentë",
  "corkë",
  "dëbim",
  "pilog",
  "pezul",
  "dasmë",
  "kukat",
  "eshtër",
  "rihet",
  "plakë",
  "bushak",
  "dozoj",
  "gjerak",
  "bashku",
  "rrënok",
  "nunël",
  "padëm",
  "afras",
  "vezme",
  "prani",
  "etapë",
  "sitac",
  "dikush",
  "buvesh",
  "gorec",
  "lagem",
  "predhë",
  "larosh",
  "peshim",
  "falmë",
  "lëfyt",
  "litër",
  "surrat",
  "tonik",
  "këllëç",
  "yçkël",
  "rrunak",
  "nyjor",
  "gamit",
  "gamull",
  "hundç",
  "mekës",
  "betar",
  "duhaç",
  "lehtë",
  "kakin",
  "dinar",
  "sokak",
  "zhalon",
  "nëntë",
  "lymak",
  "cërkë",
  "zheloj",
  "ledhas",
  "agrar",
  "gojash",
  "ojnik",
  "tagër",
  "mashën",
  "herdhe",
  "herët",
  "gëlon",
  "cupil",
  "arrore",
  "dudum",
  "bigëz",
  "parmë",
  "lepec",
  "fashit",
  "gushak",
  "sfidë",
  "dorës",
  "qepës",
  "kubik",
  "rrëmet",
  "hejdë",
  "shalok",
  "bucak",
  "terje",
  "afërt",
  "ferros",
  "leshtë",
  "sivër",
  "qetoj",
  "vishta",
  "mekët",
  "ndytë",
  "jeshil",
  "vakje",
  "sheshtë",
  "fejoj",
  "nëntë",
  "medet",
  "rritës",
  "lypet",
  "koqit",
  "miushe",
  "fjalë",
  "pejzë",
  "pitar",
  "ritëm",
  "shëtit",
  "mesap",
  "kopër",
  "qetas",
  "çunak",
  "velët",
  "currim",
  "minuk",
  "kalush",
  "barash",
  "farec",
  "lehur",
  "kacer",
  "laruk",
  "qorroj",
  "zdati",
  "domkë",
  "zhvjell",
  "hipje",
  "ildis",
  "rrufak",
  "jodur",
  "zhigël",
  "groshë",
  "flakë",
  "cibël",
  "kupëz",
  "atyre",
  "amvis",
  "faroj",
  "varosh",
  "idhnar",
  "portë",
  "osman",
  "ndarë",
  "cytje",
  "vozit",
  "shushull",
  "emnak",
  "tymoj",
  "veshëz",
  "guxoj",
  "zuzar",
  "kopan",
  "vlagë",
  "sinap",
  "llakoj",
  "myhib",
  "gjëmoj",
  "zëroj",
  "mitar",
  "sharde",
  "rreptë",
  "radhoj",
  "lopor",
  "dyfish",
  "çmesë",
  "dyfish",
  "shurup",
  "çelur",
  "përrua",
  "frikë",
  "lutur",
  "zalis",
  "branë",
  "nesër",
  "balok",
  "silazh",
  "drudhe",
  "setër",
  "latar",
  "trakt",
  "denik",
  "daulle",
  "lëkuq",
  "gërthe",
  "furem",
  "prapa",
  "dëgjim",
  "metan",
  "petës",
  "lotoj",
  "vulër",
  "ngoje",
  "susam",
  "dashas",
  "njësoj",
  "picërr",
  "haram",
  "qatip",
  "kollap",
  "sharrim",
  "çikash",
  "kalif",
  "teste",
  "llonxhë",
  "gushem",
  "njëjar",
  "batak",
  "zgrip",
  "gjithaq",
  "fitoj",
  "shënjim",
  "shkrim",
  "ardhje",
  "joshje",
  "shurrëz",
  "shembë",
  "rrokme",
  "kthyer",
  "krimb",
  "ciknë",
  "virua",
  "shogan",
  "qethje",
  "lëfos",
  "heqje",
  "zurna",
  "furem",
  "arkëz",
  "bajgë",
  "vajim",
  "vodkë",
  "zhgjyrç",
  "cufël",
  "vezor",
  "rremall",
  "kulloj",
  "baros",
  "rubai",
  "vilez",
  "kaçul",
  "bramsh",
  "ndrydh",
  "zagën",
  "lavar",
  "rrëgjyl",
  "gjallim",
  "mbroth",
  "dikur",
  "rrëbyth",
  "endje",
  "kalim",
  "vishem",
  "pikim",
  "kaskë",
  "lidhje",
  "kashta",
  "torbë",
  "mangë",
  "ndotë",
  "zhurmë",
  "argull",
  "polic",
  "sonte",
  "taksi",
  "zenit",
  "parthi",
  "çekan",
  "mesap",
  "rëngë",
  "amshoj",
  "lidhur",
  "hyrës",
  "radhit",
  "buçuk",
  "spahi",
  "kukëz",
  "kullmë",
  "lezet",
  "lëpij",
  "vejan",
  "tytosh",
  "syulli",
  "dorik",
  "varda",
  "sepse",
  "lumtë",
  "parëz",
  "thirrës",
  "faqell",
  "cërran",
  "shkopth",
  "tapos",
  "luspë",
  "këllëf",
  "vathor",
  "zburth",
  "shqerrë",
  "ardhës",
  "beloj",
  "penjtë",
  "qafok",
  "tipos",
  "këlysh",
  "bilur",
  "barmë",
  "shkumë",
  "bazim",
  "semit",
  "qezër",
  "rasat",
  "drugë",
  "spreth",
  "shpirrë",
  "dyfish",
  "kobzi",
  "abrash",
  "hiqem",
  "leshje",
  "memur",
  "fejzë",
  "shenjet",
  "nuhat",
  "tatim",
  "galuc",
  "kalas",
  "lokal",
  "pasur",
  "qetës",
  "kërthi",
  "gogol",
  "çinërr",
  "rrëshim",
  "terroj",
  "radhua",
  "strem",
  "takëm",
  "tipik",
  "fushim",
  "botor",
  "kalit",
  "dhjetë",
  "qullash",
  "brukë",
  "gufim",
  "mollëz",
  "huhem",
  "shoshit",
  "litar",
  "lilak",
  "konjak",
  "kumar",
  "mihje",
  "zaten",
  "pishtë",
  "rruazë",
  "jonxhë",
  "qafuk",
  "gishte",
  "dupje",
  "xheloz",
  "drang",
  "midhje",
  "thirror",
  "dhunas",
  "pakët",
  "qitro",
  "grirë",
  "macurr",
  "shalës",
  "aullin",
  "rëndë",
  "ashpër",
  "qartë",
  "rrojës",
  "thonjtë",
  "buzëz",
  "pendë",
  "bibël",
  "laris",
  "rabush",
  "argat",
  "rrokëz",
  "dhirtë",
  "kunjas",
  "rehen",
  "blanjë",
  "lëngë",
  "eshtak",
  "kokël",
  "brisë",
  "afishe",
  "skutë",
  "shurroj",
  "lodhët",
  "shfrej",
  "ajërt",
  "feste",
  "glyrë",
  "ofshaj",
  "cytem",
  "vilet",
  "byrek",
  "skllop",
  "rudak",
  "cinik",
  "enkas",
  "rysem",
  "zhymtë",
  "kërrus",
  "akëku",
  "hapur",
  "lashtë",
  "dhenar",
  "tullar",
  "rrotoj",
  "larim",
  "butac",
  "mbirë",
  "miras",
  "çjerrë",
  "regjim",
  "bufthi",
  "zbekth",
  "sharak",
  "gjetës",
  "lëmim",
  "kurrgjë",
  "lugaç",
  "thonjëz",
  "venit",
  "shpurë",
  "gjobar",
  "lijos",
  "larok",
  "tirret",
  "çartë",
  "bebëz",
  "hedhur",
  "lyrës",
  "shodol",
  "ajros",
  "llahem",
  "ujtis",
  "idiot",
  "shlyer",
  "galmë",
  "klikë",
  "akord",
  "çetëz",
  "gjymti",
  "barxhë",
  "alarm",
  "turit",
  "bazik",
  "shpreh",
  "korte",
  "lacan",
  "zagar",
  "ujëse",
  "vllajë",
  "trini",
  "dhishkë",
  "ndeshë",
  "matkë",
  "radar",
  "gramë",
  "rrasik",
  "atillë",
  "sojak",
  "hallall",
  "starë",
  "shurraq",
  "gamërr",
  "nikel",
  "sosur",
  "horras",
  "gugush",
  "surle",
  "tekur",
  "çezmë",
  "frezë",
  "psalt",
  "lodhur",
  "agzot",
  "karthë",
  "arkiv",
  "hapur",
  "cacër",
  "tonazh",
  "rrëzall",
  "ushezë",
  "cytur",
  "panxhë",
  "gjëllim",
  "penik",
  "skarë",
  "pagur",
  "gugat",
  "pasoj",
  "shqipo",
  "lejoj",
  "çilur",
  "shegat",
  "pudër",
  "epror",
  "lutur",
  "noçkë",
  "fizik",
  "maket",
  "capok",
  "cullan",
  "jepem",
  "çetar",
  "trokth",
  "anemi",
  "mugët",
  "larik",
  "pashoq",
  "sajdi",
  "cuboj",
  "melës",
  "shputë",
  "guxim",
  "veshas",
  "kobash",
  "akullt",
  "ullulla",
  "flotë",
  "hakoç",
  "pishak",
  "kimet",
  "pruaj",
  "suval",
  "brevë",
  "sheleg",
  "lulëz",
  "kunat",
  "luhet",
  "qyqar",
  "rrahje",
  "qofte",
  "verdhë",
  "vrarë",
  "tymar",
  "sekush",
  "vençë",
  "pepeq",
  "cakos",
  "brolle",
  "gjunjar",
  "gemem",
  "pikëz",
  "vijëz",
  "përreth",
  "prift",
  "zabua",
  "bukur",
  "vëllath",
  "shitje",
  "shpues",
  "çikje",
  "pojak",
  "polar",
  "vijoj",
  "zamkë",
  "fakir",
  "kanal",
  "garroq",
  "minus",
  "tutël",
  "enemi",
  "qepem",
  "qurrash",
  "njëjës",
  "qelan",
  "titan",
  "agror",
  "ryzoj",
  "birim",
  "hutim",
  "butër",
  "gjinor",
  "rrëqeth",
  "ferrcë",
  "qosem",
  "amulli",
  "banoj",
  "sajoj",
  "picak",
  "palloj",
  "kallëz",
  "rrasor",
  "avari",
  "lirik",
  "qelëz",
  "dudësh",
  "hasër",
  "zishëm",
  "gëzim",
  "gafil",
  "rropat",
  "prerë",
  "mullis",
  "pekul",
  "sepje",
  "kashtë",
  "shorti",
  "kotec",
  "begun",
  "bolbë",
  "motar",
  "grusht",
  "korrës",
  "silah",
  "godit",
  "rraskë",
  "kabllo",
  "urban",
  "nosit",
  "ngjalë",
  "tharti",
  "mëkoj",
  "ënjtje",
  "bythak",
  "thepël",
  "ideal",
  "rrohet",
  "gufkë",
  "çarës",
  "therkë",
  "pranë",
  "shqirrë",
  "pendë",
  "thekem",
  "çerep",
  "rrokem",
  "veshje",
  "kular",
  "qafas",
  "dajan",
  "zjarrth",
  "shestë",
  "shpoti",
  "samar",
  "dyqan",
  "ndoca",
  "rucoj",
  "kupor",
  "sofat",
  "grerë",
  "sodit",
  "tharje",
  "guzhmë",
  "fshiqe",
  "strum",
  "hekur",
  "matje",
  "fallxhi",
  "vapje",
  "zhuzhull",
  "ndasi",
  "njimti",
  "shurdhe",
  "bythas",
  "myjshë",
  "pronë",
  "dhishtë",
  "velur",
  "gushar",
  "qeshur",
  "nyjëz",
  "lagët",
  "kthyer",
  "kariq",
  "kaçel",
  "pesësh",
  "gradë",
  "kuroj",
  "uiski",
  "kaqol",
  "zanor",
  "kikël",
  "akrep",
  "lugat",
  "zhytje",
  "gushan",
  "njësit",
  "lezar",
  "titull",
  "bollëz",
  "sojli",
  "irnos",
  "cikol",
  "hidhur",
  "shemër",
  "nemte",
  "murrak",
  "saraç",
  "lakor",
  "filloj",
  "epope",
  "rebus",
  "zhvoshk",
  "limët",
  "syrëz",
  "kukudh",
  "kyçur",
  "viçar",
  "bardhë",
  "thashëm",
  "lakim",
  "qashtu",
  "kunjoj",
  "pikje",
  "myfti",
  "qymez",
  "finok",
  "maraj",
  "dehje",
  "vedër",
  "ndanë",
  "mitos",
  "çibuk",
  "surmë",
  "tumor",
  "ngazë",
  "rrënjës",
  "mëqik",
  "rojsi",
  "sitkë",
  "latim",
  "netoj",
  "vesit",
  "qëllim",
  "hënor",
  "urdhër",
  "qukël",
  "dënga",
  "raven",
  "zorim",
  "çfarë",
  "puçik",
  "rezhdë",
  "urezë",
  "hithës",
  "vijor",
  "gjizar",
  "nazik",
  "thatush",
  "tekur",
  "mbroj",
  "gozhup",
  "gëzhit",
  "cepik",
  "andej",
  "endje",
  "zdryp",
  "bresë",
  "qeshur",
  "lirët",
  "katil",
  "frashi",
  "anale",
  "zhgrap",
  "mosgjë",
  "afërm",
  "sorrmë",
  "ndijë",
  "rinoj",
  "gojor",
  "lyhem",
  "përrlë",
  "dashez",
  "astar",
  "rremash",
  "njëlloj",
  "bogël",
  "filik",
  "rrufit",
  "terzi",
  "mardhë",
  "pulis",
  "lakër",
  "çepka",
  "gjatoj",
  "palesh",
  "ligjim",
  "hanko",
  "splon",
  "zeher",
  "nipoll",
  "veçqë",
  "dihat",
  "anore",
  "faror",
  "miskë",
  "shpurdh",
  "fryrë",
  "qafol",
  "gomën",
  "fetar",
  "obobo",
  "banak",
  "zezoj",
  "kotas",
  "helaq",
  "sugar",
  "pamiq",
  "metro",
  "hardhi",
  "mekur",
  "kusar",
  "lulëz",
  "lagës",
  "tizgë",
  "fryrë",
  "grevë",
  "kumet",
  "bashki",
  "çapëz",
  "prevë",
  "kazmë",
  "zijosh",
  "jetim",
  "rëndë",
  "njollos",
  "kilësh",
  "pelar",
  "geman",
  "bakull",
  "kallem",
  "ndrag",
  "falem",
  "lemzë",
  "qurrac",
  "foton",
  "stuhi",
  "bagazh",
  "dikoj",
  "sebep",
  "limoj",
  "lozak",
  "piqem",
  "midis",
  "thagmë",
  "lisni",
  "ganez",
  "halor",
  "atëri",
  "krejt",
  "javor",
  "qefull",
  "qershi",
  "fyrbë",
  "fushar",
  "metal",
  "limfë",
  "suazë",
  "arrirë",
  "shtyrë",
  "zhytër",
  "bigët",
  "lakoç",
  "axhele",
  "gazhel",
  "timar",
  "koras",
  "pizël",
  "myjtë",
  "rrembth",
  "blanë",
  "cingo",
  "rriskë",
  "uzinë",
  "kockë",
  "larvë",
  "muroj",
  "ujtis",
  "lidhor",
  "llurbë",
  "mamuz",
  "rroftë",
  "uzurë",
  "derëz",
  "iftar",
  "tatull",
  "cikël",
  "vepër",
  "arixhi",
  "çikem",
  "nxirë",
  "bazoj",
  "zgjuar",
  "llopës",
  "lloskë",
  "rreckë",
  "tarbë",
  "gjallje",
  "larëz",
  "veroj",
  "pakëz",
  "radhim",
  "njuton",
  "mufkë",
  "sumak",
  "shumim",
  "ditur",
  "dockë",
  "larës",
  "çokas",
  "xhinde",
  "vjela",
  "anshëm",
  "bacil",
  "leshko",
  "thartë",
  "bukur",
  "gjakos",
  "çetim",
  "shevro",
  "shpirt",
  "spathi",
  "stinë",
  "viran",
  "endës",
  "skllop",
  "çapit",
  "sëlloj",
  "sehir",
  "lythët",
  "çikëz",
  "listë",
  "armik",
  "rrugor",
  "hatull",
  "rrasash",
  "puthur",
  "meçkë",
  "lijim",
  "ujacë",
  "dherrush",
  "duvak",
  "shtrek",
  "palok",
  "garbi",
  "afion",
  "carrok",
  "pafkë",
  "dylbi",
  "skutë",
  "zhugan",
  "melcë",
  "mollak",
  "majmë",
  "lyrët",
  "firmë",
  "dërrmi",
  "belluz",
  "notoj",
  "shëmoj",
  "kulim",
  "zënkë",
  "cubel",
  "qullët",
  "dhënës",
  "rrëgjoll",
  "fikur",
  "aheng",
  "kryej",
  "beton",
  "irnoj",
  "vezir",
  "ushtar",
  "varje",
  "shumtë",
  "pruaj",
  "rrakat",
  "puqje",
  "flamë",
  "tejmë",
  "skile",
  "finjos",
  "mizor",
  "topil",
  "mugët",
  "hënor",
  "kotorr",
  "dënim",
  "shkalc",
  "skort",
  "ungjill",
  "futës",
  "nimfë",
  "vangë",
  "hisëll",
  "fshira",
  "çakërr",
  "stivë",
  "delltë",
  "gaztë",
  "lidhak",
  "rrolbë",
  "shullën",
  "nisje",
  "potir",
  "pistë",
  "aortë",
  "tokës",
  "lashkë",
  "dysor",
  "lavaç",
  "thithem",
  "zgjuath",
  "bigorr",
  "fillzi",
  "dizgë",
  "afshët",
  "vijim",
  "kaçak",
  "thirrje",
  "mezat",
  "lurkë",
  "sykuq",
  "krisë",
  "limbë",
  "sherif",
  "nderë",
  "qullac",
  "hijeç",
  "pestë",
  "futur",
  "gushoj",
  "shqilë",
  "varroj",
  "puçërr",
  "zanat",
  "pupthi",
  "gican",
  "prevë",
  "mjakë",
  "katit",
  "ndore",
  "nofull",
  "dyshas",
  "yshtur",
  "epshëm",
  "shqind",
  "cërit",
  "kuran",
  "suxhuk",
  "ferrak",
  "pejan",
  "dhitem",
  "backë",
  "shifon",
  "ëndje",
  "ardhëm",
  "dalës",
  "birrem",
  "lutje",
  "vjegë",
  "xhirim",
  "zhungë",
  "rrudhac",
  "qehën",
  "nofkë",
  "hunap",
  "bluzë",
  "zegal",
  "qatër",
  "dumkë",
  "gotull",
  "përzë",
  "llullaq",
  "mjeki",
  "madhosh",
  "hienë",
  "hapës",
  "gjuhcë",
  "kulet",
  "lakër",
  "betec",
  "vizim",
  "vllahe",
  "cipull",
  "brokë",
  "binde",
  "aneks",
  "kunup",
  "sharrëz",
  "tetar",
  "livadh",
  "makër",
  "ngjarë",
  "polkë",
  "dokërr",
  "heqje",
  "ballje",
  "thithës",
  "dajre",
  "rrezak",
  "palcë",
  "micak",
  "hyrës",
  "avdall",
  "valim",
  "sharpë",
  "lakmi",
  "lurek",
  "lypur",
  "mbarë",
  "fërli",
  "start",
  "shutull",
  "ndjek",
  "nëpër",
  "penez",
  "këmbë",
  "gjerush",
  "provë",
  "dejëz",
  "dakol",
  "drizë",
  "shtang",
  "amorf",
  "patos",
  "gaxhit",
  "zhabem",
  "bombë",
  "ngallë",
  "bagëm",
  "shtënë",
  "ardhur",
  "burran",
  "hamam",
  "mërij",
  "tahin",
  "shegan",
  "filiz",
  "faqoj",
  "kuqël",
  "tupan",
  "qibar",
  "kolon",
  "mbjell",
  "gjëshëm",
  "kërriç",
  "njësor",
  "bemol",
  "bormë",
  "regjem",
  "ganec",
  "lëfar",
  "lugëz",
  "trizë",
  "shtyllë",
  "sfurk",
  "heqës",
  "varrosh",
  "përudh",
  "rrallas",
  "aspër",
  "luloj",
  "ferrës",
  "shyqyr",
  "llajush",
  "buzaç",
  "motak",
  "ndyrë",
  "shirit",
  "gjashtë",
  "rrallim",
  "brekë",
  "pulkë",
  "skrop",
  "armaç",
  "duhem",
  "drithë",
  "fyhem",
  "lekën",
  "thithje",
  "ujcak",
  "komshi",
  "bonjak",
  "gjitar",
  "bohet",
  "kodoj",
  "mërzi",
  "fillak",
  "azgan",
  "synoj",
  "beter",
  "fajkë",
  "divan",
  "lugim",
  "kapës",
  "mëshim",
  "ndalk",
  "klekë",
  "rodhan",
  "anëse",
  "rogaç",
  "hajni",
  "gojcë",
  "tullar",
  "molëz",
  "mokër",
  "shterp",
  "misër",
  "vdjerr",
  "keqem",
  "mohor",
  "malor",
  "xhamor",
  "rrëshek",
  "tenor",
  "gatit",
  "perçe",
  "mehet",
  "sillem",
  "mesazh",
  "shuhem",
  "regjës",
  "vrasë",
  "shinik",
  "ledhtë",
  "dorak",
  "penoj",
  "xunkth",
  "mujzë",
  "peshin",
  "dollma",
  "leshzi",
  "thirrur",
  "gjumës",
  "paudhë",
  "pazar",
  "pazot",
  "babush",
  "murrash",
  "katëk",
  "buzik",
  "thellim",
  "gilcë",
  "terren",
  "ujore",
  "krupë",
  "filan",
  "kepës",
  "karmë",
  "kolit",
  "rregull",
  "ngrij",
  "ditur",
  "bandë",
  "binish",
  "qahem",
  "majça",
  "lavor",
  "yshtar",
  "gjatush",
  "shkrydh",
  "harmi",
  "bifkë",
  "bukje",
  "shtalb",
  "tëharr",
  "kryer",
  "çikërr",
  "kusur",
  "tulës",
  "balet",
  "kresht",
  "qymyr",
  "syenë",
  "thekem",
  "vithis",
  "zezkë",
  "sevda",
  "qokthi",
  "kopsë",
  "gëshill",
  "njësoj",
  "liken",
  "zdërre",
  "rripak",
  "dyzen",
  "gjator",
  "shoshet",
  "prokë",
  "grigjë",
  "çoçër",
  "mesës",
  "muzhik",
  "vënës",
  "shijim",
  "vetor",
  "hyxhym",
  "shahit",
  "nismë",
  "marroq",
  "pulith",
  "llonxhë",
  "savan",
  "çerdhe",
  "lagie",
  "dyjar",
  "cergë",
  "zerva",
  "zgjuar",
  "gushiq",
  "oshtin",
  "biroj",
  "parti",
  "çyrek",
  "marrës",
  "datoj",
  "boskë",
  "foshnjë",
  "qeste",
  "lëviz",
  "dashur",
  "njomëz",
  "pordhë",
  "mahis",
  "tajit",
  "çokush",
  "altar",
  "rojkë",
  "sfakë",
  "fermë",
  "zhytës",
  "njerkë",
  "harrje",
  "lotim",
  "njëckë",
  "sitje",
  "çekem",
  "puthit",
  "paqoj",
  "fagot",
  "bukje",
  "matem",
  "maraz",
  "hollak",
  "futës",
  "demet",
  "dehet",
  "buzël",
  "krape",
  "favor",
  "rripoj",
  "llagap",
  "sipas",
  "album",
  "shpend",
  "lorëz",
  "çapthi",
  "cakos",
  "kacek",
  "rebel",
  "zverdh",
  "fendë",
  "anxhik",
  "buzhel",
  "rritës",
  "imshtë",
  "mposht",
  "qukëç",
  "dehur",
  "xhanëm",
  "shkurt",
  "spërk",
  "gjunjëz",
  "vetëm",
  "gaviç",
  "stemë",
  "ujdis",
  "anësi",
  "shërim",
  "shkëmb",
  "rruvan",
  "kësaj",
  "xhakon",
  "dyllos",
  "lugës",
  "pleng",
  "rrogëz",
  "kamosh",
  "llaçkë",
  "sherrxhi",
  "lypës",
  "kavak",
  "shtijë",
  "qazhnë",
  "rrunëz",
  "pasor",
  "mihet",
  "suitë",
  "arkas",
  "kajsi",
  "ferroj",
  "aneks",
  "maunë",
  "xhamtë",
  "çafkë",
  "shqipe",
  "shqisë",
  "gurës",
  "shtymë",
  "ahere",
  "cinik",
  "thëthij",
  "dheshëm",
  "sitka",
  "egras",
  "jehon",
  "njëfish",
  "kridhë",
  "shukem",
  "çarshi",
  "fytje",
  "turmë",
  "rrezor",
  "harrim",
  "keqan",
  "rrotkë",
  "ngaqë",
  "shkrij",
  "xhenio",
  "endur",
  "sarkë",
  "plasë",
  "birbo",
  "urryer",
  "mollok",
  "buron",
  "sedër",
  "trand",
  "gjakim",
  "milic",
  "zgorre",
  "bujar",
  "gabim",
  "shtirë",
  "rëndë",
  "mokërr",
  "dërrmë",
  "krela",
  "mesor",
  "kungë",
  "bursë",
  "miellt",
  "senet",
  "kokës",
  "joshem",
  "votim",
  "shpezë",
  "mukon",
  "zhibël",
  "vurrat",
  "saksi",
  "habit",
  "hashash",
  "gjeluc",
  "vesoj",
  "kaloj",
  "pashai",
  "zhytur",
  "bullur",
  "viskë",
  "skllup",
  "kokorr",
  "çerte",
  "dorac",
  "tultë",
  "veshak",
  "kocak",
  "angërr",
  "sjellë",
  "vresht",
  "fikje",
  "gërdhu",
  "bushiq",
  "zhuzhak",
  "veshul",
  "zhubër",
  "terik",
  "guacë",
  "pulit",
  "thatak",
  "oleve",
  "sykath",
  "shulës",
  "majtë",
  "thanës",
  "vezak",
  "gufas",
  "qitës",
  "kaçorr",
  "buacë",
  "tjerë",
  "pohim",
  "mbëhi",
  "notar",
  "çajme",
  "tukeq",
  "petas",
  "sulem",
  "bardho",
  "lëkur",
  "tetësh",
  "turshi",
  "tashme",
  "pigme",
  "fiqir",
  "tufan",
  "bravë",
  "zhezhit",
  "verdhë",
  "mitan",
  "pagan",
  "gjegjem",
  "stallë",
  "moçëm",
  "hupët",
  "ketër",
  "folës",
  "muajsh",
  "bohçe",
  "puthje",
  "cuban",
  "cirua",
  "lagjin",
  "bërruc",
  "cecull",
  "çurkë",
  "afgan",
  "rrethas",
  "arrest",
  "boçkë",
  "fatos",
  "lyrem",
  "ajnik",
  "shqelm",
  "mjaft",
  "xhumar",
  "gërje",
  "këqyr",
  "hapem",
  "mëzor",
  "rihem",
  "rreshpe",
  "rralluk",
  "thonjaz",
  "vizgë",
  "nxjerr",
  "lutës",
  "lacër",
  "guroj",
  "tupak",
  "kapak",
  "çahem",
  "saxhak",
  "llackë",
  "valoj",
  "rrasit",
  "humor",
  "qershi",
  "fikat",
  "qofkë",
  "vazhdë",
  "dëgjoj",
  "torkë",
  "dollap",
  "xherkë",
  "kepje",
  "thahem",
  "vergë",
  "zgjumth",
  "tallës",
  "stikë",
  "duduk",
  "stere",
  "zhurit",
  "ditën",
  "mykur",
  "copit",
  "forras",
  "ligur",
  "letër",
  "tifoz",
  "etnik",
  "bymej",
  "anuar",
  "prajë",
  "thelloj",
  "zigur",
  "fetar",
  "shquaj",
  "shkuar",
  "truall",
  "divan",
  "kopuk",
  "navig",
  "cakthi",
  "cekem",
  "thekon",
  "tingë",
  "kuban",
  "cekje",
  "bimël",
  "nizam",
  "lëmek",
  "mushkë",
  "rinor",
  "taroj",
  "çalash",
  "bullar",
  "prozë",
  "korith",
  "xhenet",
  "zbrum",
  "qysqi",
  "xhamash",
  "fushki",
  "veror",
  "bufkë",
  "qimush",
  "droqe",
  "barun",
  "brenë",
  "mugët",
  "tanor",
  "leton",
  "zonak",
  "sarëk",
  "gjatok",
  "bathër",
  "sargi",
  "dhogiç",
  "prethi",
  "rudak",
  "shalqi",
  "madje",
  "folur",
  "kishkë",
  "meshin",
  "ruboj",
  "vjelë",
  "tumul",
  "tipar",
  "korse",
  "hirrët",
  "biçki",
  "ligjoj",
  "qyfyr",
  "paide",
  "gunar",
  "reshem",
  "telash",
  "laurë",
  "tratë",
  "domen",
  "dujkë",
  "palas",
  "cekash",
  "mbesë",
  "jetos",
  "çevre",
  "faqëz",
  "gugash",
  "hapët",
  "kaike",
  "limit",
  "forum",
  "derës",
  "çjerrë",
  "jelek",
  "dungë",
  "thekër",
  "gjethon",
  "bafër",
  "shojzë",
  "bruto",
  "gomoj",
  "krosë",
  "jashtë",
  "herët",
  "pistë",
  "yshtem",
  "carrua",
  "tutër",
  "qethem",
  "kokull",
  "prekë",
  "sahat",
  "telish",
  "bubël",
  "hahem",
  "bixan",
  "spiun",
  "turrem",
  "kanxhë",
  "hukat",
  "llokum",
  "hudhër",
  "aktiv",
  "nejse",
  "balër",
  "albër",
  "çallëz",
  "marrje",
  "anazi",
  "bishte",
  "batec",
  "lanet",
  "blues",
  "dragë",
  "afërm",
  "zegël",
  "ashure",
  "zënës",
  "sherrak",
  "ushqej",
  "mishje",
  "gresë",
  "gjuhëz",
  "nëmës",
  "shkreh",
  "sëllij",
  "myzhde",
  "hunde",
  "çikoj",
  "curran",
  "varur",
  "curril",
  "kaluç",
  "nipash",
  "fushak",
  "batoq",
  "planë",
  "zhulos",
  "kuhem",
  "mplak",
  "gjirak",
  "harret",
  "potas",
  "zlloti",
  "tuhat",
  "shques",
  "pashëm",
  "kuotë",
  "shquar",
  "briçe",
  "lamash",
  "doker",
  "rroboç",
  "plusk",
  "sport",
  "xhixhëll",
  "rival",
  "cingë",
  "nigjah",
  "vegjoj",
  "pjatë",
  "bulëz",
  "farëz",
  "paerë",
  "endëz",
  "vrenjt",
  "rënie",
  "aliazh",
  "velem",
  "vërsë",
  "hurmë",
  "rragam",
  "ndalë",
  "etshëm",
  "mekam",
  "shpitë",
  "gjashtë",
  "gjueti",
  "bardhë",
  "cefël",
  "gajas",
  "çeshit",
  "marxhë",
  "komik",
  "gojos",
  "tabak",
  "logjik",
  "kukunj",
  "xëgit",
  "gushosh",
  "ndrin",
  "lafshë",
  "qebap",
  "selit",
  "breshk",
  "vilar",
  "lakër",
  "thiktë",
  "zgrap",
  "kënut",
  "tirazh",
  "cenos",
  "shufël",
  "kufje",
  "dreqe",
  "kafkë",
  "lepur",
  "batull",
  "zdrit",
  "stelë",
  "endës",
  "mëtim",
  "grifë",
  "rabat",
  "arenë",
  "mëhorr",
  "mullosh",
  "stoik",
  "fytës",
  "njomës",
  "horroj",
  "epikë",
  "thermë",
  "muzgë",
  "lolit",
  "cërrik",
  "çitar",
  "lejor",
  "mysët",
  "fustë",
  "loçkë",
  "shpuar",
  "tashti",
  "epërm",
  "belcë",
  "elips",
  "ikonë",
  "robit",
  "vuhet",
  "lebër",
  "njëzaj",
  "gëthuq",
  "dybek",
  "këtij",
  "cekoj",
  "armik",
  "pamish",
  "salcë",
  "dishtë",
  "çaçkë",
  "jahni",
  "ferrës",
  "mafes",
  "çudit",
  "hikërr",
  "mishmë",
  "melez",
  "fiçor",
  "futem",
  "sykëm",
  "rrokme",
  "rrjepë",
  "eptim",
  "brukth",
  "qopal",
  "frutë",
  "struc",
  "rrotëz",
  "cumër",
  "shapkë",
  "buzje",
  "estet",
  "dogra",
  "lyrim",
  "borak",
  "veças",
  "ngjarë",
  "dhomëz",
  "dobët",
  "libër",
  "qetar",
  "zbledh",
  "kunel",
  "danez",
  "llomit",
  "hipës",
  "romuz",
  "plagë",
  "njohur",
  "rrashtë",
  "rrokës",
  "ickël",
  "qukem",
  "mykor",
  "llumtë",
  "vizor",
  "rrudhje",
  "llagëm",
  "tenjos",
  "gjalloj",
  "sharmë",
  "total",
  "fijez",
  "mykëz",
  "ledhoj",
  "dashur",
  "vlerë",
  "linjtë",
  "astmë",
  "dalldi",
  "dyshim",
  "shkota",
  "heqim",
  "buhur",
  "pjekë",
  "drenjë",
  "qullem",
  "laris",
  "lagur",
  "parak",
  "tepër",
  "pulith",
  "thithës",
  "pitër",
  "kolegj",
  "lëshim",
  "ngjyer",
  "fidhës",
  "zemër",
  "fqollë",
  "ditor",
  "cijat",
  "sarma",
  "cucërr",
  "fillim",
  "shtazë",
  "tehri",
  "capoj",
  "tulor",
  "tamah",
  "milet",
  "lakut",
  "bajun",
  "çimkë",
  "forcë",
  "lashti",
  "lëmuq",
  "cegëm",
  "çifut",
  "zhegoj",
  "pleng",
  "vajzë",
  "kripth",
  "saten",
  "pirit",
  "torrlë",
  "anesë",
  "shtonë",
  "kollum",
  "hedhur",
  "tretë",
  "thanëz",
  "çiban",
  "petor",
  "pajis",
  "gjobit",
  "dashur",
  "kamur",
  "balos",
  "popël",
  "skotë",
  "kimik",
  "rasor",
  "tekqë",
  "dëkoj",
  "qeros",
  "çmues",
  "pyjor",
  "shelqe",
  "gufar",
  "argan",
  "perlë",
  "thesar",
  "damar",
  "ligjim",
  "myhyr",
  "qitje",
  "pitok",
  "bardhë",
  "ylber",
  "pastë",
  "shahem",
  "marroj",
  "akrep",
  "salep",
  "jevgë",
  "lavos",
  "balosh",
  "trazë",
  "tjerrë",
  "buqme",
  "dhëmbo",
  "vikat",
  "rrugoj",
  "reçel",
  "ofset",
  "kresë",
  "shihem",
  "kallcë",
  "bollëk",
  "fatzi",
  "resit",
  "reshpe",
  "plëng",
  "lakoj",
  "shulaq",
  "kubik",
  "ndrit",
  "hanxhi",
  "biban",
  "jetull",
  "zabel",
  "eksiq",
  "panik",
  "lavdi",
  "supëz",
  "sejtë",
  "çudoj",
  "koret",
  "gingë",
  "harbi",
  "vjeshk",
  "stihi",
  "lokal",
  "putër",
  "drogë",
  "rrepan",
  "hyrje",
  "bulas",
  "xibërr",
  "gogël",
  "rrokoll",
  "çaloj",
  "pjacë",
  "zogël",
  "kijat",
  "hijëz",
  "fjali",
  "sferë",
  "rrugaç",
  "lehje",
  "rrehem",
  "rrugëz",
  "hojëz",
  "hidhur",
  "buhit",
  "paqoj",
  "hamës",
  "mëkat",
  "gogesh",
  "citat",
  "gëthep",
  "tumbë",
  "fanar",
  "pjellë",
  "koleg",
  "pritë",
  "kosto",
  "ligët",
  "amper",
  "laros",
  "zëmër",
  "nojmë",
  "daltë",
  "fugas",
  "qarrtë",
  "çoban",
  "koral",
  "beshtë",
  "kopek",
  "gazel",
  "rrajzë",
  "fitëz",
  "netull",
  "qeshër",
  "gjyryk",
  "çukël",
  "çyryk",
  "florë",
  "vetmi",
  "arithi",
  "motës",
  "tabak",
  "brumç",
  "goren",
  "gjëllij",
  "çikël",
  "bulçi",
  "fshesë",
  "selam",
  "shtreth",
  "murgë",
  "lirar",
  "gjellëz",
  "hiroj",
  "djathë",
  "zëshëm",
  "lahem",
  "gufac",
  "vidhos",
  "gurtë",
  "shqarth",
  "hënëz",
  "gjiroj",
  "mullos",
  "bares",
  "dhunoj",
  "buxhak",
  "bulon",
  "qesat",
  "fugem",
  "prirë",
  "frymë",
  "sajim",
  "kujri",
  "avion",
  "lumos",
  "dhenor",
  "tamërr",
  "besim",
  "qeshje",
  "çirak",
  "sheshoj",
  "baloj",
  "dajak",
  "gotik",
  "qepësh",
  "filik",
  "idhnak",
  "murran",
  "bankë",
  "buzoç",
  "tajgë",
  "nipçe",
  "drekë",
  "anije",
  "çipës",
  "qenar",
  "dollak",
  "miros",
  "optik",
  "kajlë",
  "vivar",
  "thepët",
  "shenjëz",
  "heqës",
  "opium",
  "rrudhos",
  "këndë",
  "gushar",
  "toçit",
  "prapë",
  "ëndërr",
  "bronz",
  "ballkë",
  "vonim",
  "bedhoj",
  "çikëz",
  "dalje",
  "numër",
  "burrac",
  "lehtë",
  "samsa",
  "vemje",
  "nakël",
  "shpuar",
  "ethshëm",
  "zerde",
  "shulem",
  "bronk",
  "kerrmë",
  "kabull",
  "tellall",
  "themel",
  "aradhe",
  "finok",
  "shurdha",
  "rrezoj",
  "llupash",
  "kanun",
  "dhurim",
  "rrethak",
  "rritur",
  "ënjtur",
  "qemër",
  "qullos",
  "pamje",
  "smirë",
  "front",
  "mumie",
  "belor",
  "karpë",
  "bricë",
  "memec",
  "tezgë",
  "motiv",
  "telef",
  "nisem",
  "banal",
  "rropos",
  "hollush",
  "çufër",
  "tinëz",
  "hatër",
  "tejet",
  "larës",
  "qylah",
  "model",
  "lanok",
  "qurran",
  "batoj",
  "rrjedhë",
  "balash",
  "ganez",
  "tokëz",
  "ostri",
  "qafoj",
  "njihem",
  "dehem",
  "jetik",
  "çorap",
  "kthinë",
  "lëbyr",
  "rrozgë",
  "labot",
  "zezak",
  "karrup",
  "cjape",
  "thinjosh",
  "samtë",
  "haset",
  "gjykim",
  "kallaj",
  "hiçgjë",
  "petor",
  "urgus",
  "nullëz",
  "kërret",
  "hupës",
  "dietë",
  "firoj",
  "blozë",
  "thepak",
  "tajer",
  "stolë",
  "gazoj",
  "rinim",
  "mbarë",
  "rregull",
  "ballor",
  "blanë",
  "shukull",
  "etuar",
  "matës",
  "humus",
  "xhamthi",
  "gulçi",
  "jargë",
  "qepër",
  "hidhës",
  "fytthi",
  "kashai",
  "taraf",
  "sharkë",
  "shmang",
  "basmë",
  "varës",
  "larosh",
  "vrimë",
  "gjyveç",
  "tashmë",
  "gjysmë",
  "grezhë",
  "ajkët",
  "palos",
  "sertë",
  "kalem",
  "vatër",
  "gatem",
  "gajde",
  "dahuk",
  "leshtë",
  "shutër",
  "rëkosh",
  "arbër",
  "damkë",
  "komtë",
  "cërit",
  "plloçë",
  "sigël",
  "dasik",
  "djalë",
  "qohem",
  "fidan",
  "lëndë",
  "koqëz",
  "biçim",
  "milje",
  "gjetkë",
  "plumb",
  "grurth",
  "qelqe",
  "laran",
  "mëtoj",
  "prier",
  "pasur",
  "çapua",
  "befas",
  "bufkë",
  "belas",
  "moçal",
  "llabiç",
  "rraberr",
  "sisor",
  "bërje",
  "trumë",
  "stoik",
  "tokël",
  "verdhë",
  "vapim",
  "uruar",
  "miros",
  "abece",
  "thjerrë",
  "vihem",
  "çikma",
  "buçko",
  "bixhoz",
  "binek",
  "fisëm",
  "tajkë",
  "acari",
  "vakër",
  "helme",
  "ashkël",
  "gërdi",
  "lëndi",
  "gicthi",
  "komik",
  "hurdhë",
  "mitër",
  "radhas",
  "treme",
  "degëz",
  "shkrap",
  "shushël",
  "ndotë",
  "qeçkë",
  "badër",
  "oderra",
  "vrarë",
  "hasem",
  "bubëz",
  "pulëz",
  "kënaq",
  "liroj",
  "kolmë",
  "mishtë",
  "herak",
  "birko",
  "femër",
  "mohim",
  "fytas",
  "kripë",
  "thukët",
  "dashje",
  "sitet",
  "postë",
  "pikas",
  "acare",
  "kullot",
  "pitush",
  "shkurt",
  "kordhë",
  "veçoj",
  "bardhë",
  "zgjebe",
  "peshkth",
  "vadhëz",
  "leshem",
  "anësi",
  "drenë",
  "kmesë",
  "ngjyej",
  "korret",
  "qetthi",
  "pikës",
  "meshar",
  "lugët",
  "babil",
  "cabok",
  "jetim",
  "shtarë",
  "çngjit",
  "llapit",
  "blojë",
  "betim",
  "eunuk",
  "penel",
  "lëvar",
  "hedhës",
  "ballëz",
  "bidon",
  "shpellë",
  "tanin",
  "sikush",
  "telje",
  "beqar",
  "grolle",
  "sakaq",
  "elbje",
  "doçkë",
  "gullmë",
  "sorrak",
  "frëng",
  "rende",
  "lalush",
  "lesim",
  "oburra",
  "çakëll",
  "harrur",
  "pufte",
  "lingë",
  "pilloj",
  "mihës",
  "ngryk",
  "shprush",
  "zefir",
  "fuzhnjë",
  "tufël",
  "kavall",
  "sukull",
  "pësor",
  "apati",
  "cytës",
  "lehtë",
  "thupër",
  "thyhem",
  "lijoj",
  "parim",
  "verim",
  "shkret",
  "mpreh",
  "kukoj",
  "pasje",
  "zymti",
  "hovas",
  "lirim",
  "grari",
  "brajë",
  "nadir",
  "lyrës",
  "zhbrej",
  "hotel",
  "vërras",
  "shqipe",
  "hajde",
  "milak",
  "pjerdh",
  "ushtri",
  "armim",
  "mëngji",
  "thatiq",
  "jufkë",
  "rrasik",
  "rrahçë",
  "marros",
  "urdhër",
  "korit",
  "mesit",
  "kollem",
  "bulim",
  "ronit",
  "prijë",
  "meste",
  "bejçe",
  "pinjoll",
  "yshtje",
  "vizak",
  "pallat",
  "shitës",
  "kubël",
  "araba",
  "rrufull",
  "dashur",
  "susme",
  "gjirmë",
  "qukës",
  "lidhës",
  "lejim",
  "kamës",
  "zgjyrë",
  "korbë",
  "gjelëz",
  "deist",
  "dystë",
  "dorak",
  "torthi",
  "mishël",
  "madhor",
  "kalthi",
  "shënoj",
  "bahçe",
  "pirje",
  "rrebaq",
  "shtunë",
  "kacuk",
  "zagall",
  "kupon",
  "trashë",
  "silloj",
  "flori",
  "skenë",
  "frëng",
  "rehat",
  "dyshoj",
  "culan",
  "mujor",
  "shufër",
  "mensë",
  "zverk",
  "rrëgjim",
  "teksa",
  "rripor",
  "sheshit",
  "ngërç",
  "nenëz",
  "abort",
  "kobër",
  "çalëz",
  "çelëz",
  "manar",
  "murrët",
  "gardë",
  "penal",
  "legal",
  "puqur",
  "sykan",
  "hithëth",
  "paktë",
  "fshikë",
  "venom",
  "tënde",
  "danik",
  "puhat",
  "dietë",
  "karriq",
  "masur",
  "dukal",
  "volit",
  "xhahil",
  "lopthi",
  "çohem",
  "qeras",
  "gubëz",
  "lodhem",
  "pirës",
  "qarje",
  "çokit",
  "zhamel",
  "ngjyer",
  "gjalpë",
  "zgjirë",
  "caran",
  "kocan",
  "brizë",
  "burdhë",
  "burmë",
  "shatër",
  "takër",
  "vadit",
  "këput",
  "galan",
  "rosak",
  "thurje",
  "xhevap",
  "gozhdë",
  "qokaç",
  "pullaz",
  "vrokth",
  "vathoj",
  "shpagë",
  "mazit",
  "mëzak",
  "kupac",
  "hijoj",
  "thepak",
  "mitur",
  "gargë",
  "çalas",
  "apogje",
  "kryqe",
  "gjashtë",
  "zallor",
  "gjalmë",
  "lakër",
  "rentë",
  "botoj",
  "banim",
  "thirrem",
  "shahthi",
  "dumen",
  "pacak",
  "akuzë",
  "gazor",
  "çelët",
  "moral",
  "zhapem",
  "thikëz",
  "rushat",
  "veshti",
  "çetur",
  "fitme",
  "shqeto",
  "notim",
  "konik",
  "ububu",
  "mollëz",
  "druaj",
  "brirth",
  "shulas",
  "prerë",
  "zhegit",
  "modhull",
  "lymor",
  "leqoj",
  "taksë",
  "lypur",
  "tisazh",
  "gjelas",
  "vajës",
  "shakull",
  "askath",
  "baras",
  "smuqth",
  "stufë",
  "veçor",
  "kordë",
  "bitis",
  "imtas",
  "paduk",
  "molos",
  "kaçup",
  "lugës",
  "ndier",
  "ushkur",
  "shilor",
  "vickë",
  "orëzi",
  "tragë",
  "nijet",
  "kukull",
  "gorar",
  "shlyej",
  "qethëm",
  "gërric",
  "zhuktë",
  "intim",
  "drosht",
  "oshmak",
  "behar",
  "korrje",
  "baski",
  "qytyk",
  "himtë",
  "rrëzak",
  "harroj",
  "qençe",
  "tortë",
  "radhor",
  "moral",
  "katër",
  "luftë",
  "citim",
  "petal",
  "vidan",
  "morrash",
  "esnaf",
  "mauthi",
  "kokme",
  "trokë",
  "gjeras",
  "bufar",
  "sofër",
  "reshte",
  "torfë",
  "panjtë",
  "rrudhës",
  "njëfish",
  "mykje",
  "lëroj",
  "sfinë",
  "tutës",
  "marrtë",
  "nefes",
  "brisk",
  "kallëp",
  "rrasem",
  "hirës",
  "eshtor",
  "rrëfim",
  "kuarc",
  "njohje",
  "rrasët",
  "përdhe",
  "avdes",
  "mbreh",
  "harrje",
  "pllushë",
  "matës",
  "qapoj",
  "qorrim",
  "rrudhem",
  "fshirë",
  "ardhës",
  "gjinish",
  "çangë",
  "shapoj",
  "pastë",
  "sosem",
  "ardhur",
  "polen",
  "kaçup",
  "gabel",
  "parmë",
  "pinjall",
  "duhan",
  "piper",
  "molis",
  "avullt",
  "spirë",
  "batall",
  "gavër",
  "ankim",
  "tendë",
  "zotem",
  "sëmur",
  "futje",
  "hozheg",
  "bylyk",
  "poroz",
  "dackë",
  "pritë",
  "leshje",
  "capit",
  "shitur",
  "kuqash",
  "cigan",
  "binom",
  "katua",
  "qereç",
  "çmuar",
  "sollak",
  "alkal",
  "therur",
  "çafër",
  "pallje",
  "mbart",
  "folës",
  "gjakës",
  "shquall",
  "dyshek",
  "hallvë",
  "dimër",
  "koraq",
  "llapush",
  "kumri",
  "logoj",
  "rrokull",
  "zhabës",
  "gatim",
  "mishël",
  "mitik",
  "shatër",
  "drang",
  "cikon",
  "prura",
  "sharrëz",
  "mpirë",
  "evlat",
  "lozak",
  "thënie",
  "drokth",
  "arbër",
  "gjinde",
  "manak",
  "çanak",
  "ogiçe",
  "blegë",
  "fugoj",
  "çekiç",
  "gaçkë",
  "ndreq",
  "shtradh",
  "allate",
  "pasherr",
  "qëlloj",
  "krushk",
  "karat",
  "qemer",
  "shkarth",
  "thurak",
  "hulloj",
  "antik",
  "cërrlë",
  "borik",
  "bukoj",
  "dheras",
  "cenim",
  "kadet",
  "pleme",
  "shogem",
  "nëmur",
  "shëllij",
  "vasal",
  "doras",
  "stejë",
  "bigël",
  "pikem",
  "trikë",
  "mësyj",
  "qitur",
  "lavër",
  "ferrac",
  "vigan",
  "njëshëm",
  "sekull",
  "tablo",
  "cakërr",
  "qeder",
  "dalloj",
  "kashër",
  "pllakë",
  "plate",
  "zhushka",
  "kilus",
  "farës",
  "eshtek",
  "kllukë",
  "ironi",
  "elbtë",
  "synim",
  "hetim",
  "rrathje",
  "majta",
  "bakeq",
  "furroj",
  "vishnjë",
  "burmë",
  "gropë",
  "vigjëz",
  "cenoj",
  "porsa",
  "dinak",
  "vidhem",
  "nomad",
  "lyroj",
  "mbruj",
  "pompë",
  "shulak",
  "kepet",
  "solar",
  "tavllë",
  "sorkë",
  "bërës",
  "turrthi",
  "hamull",
  "gjuhor",
  "përce",
  "dehës",
  "liman",
  "sorrush",
  "qerbë",
  "muget",
  "leshnje",
  "abëcë",
  "sqyre",
  "cukël",
  "kacan",
  "arsej",
  "fytëz",
  "sefte",
  "prajë",
  "mitur",
  "ndarë",
  "skicë",
  "shtëpi",
  "bekim",
  "grykë",
  "nisur",
  "pafat",
  "laktë",
  "burim",
  "armen",
  "besoj",
  "shulor",
  "paujë",
  "rriqër",
  "shtysë",
  "kuçkë",
  "patoj",
  "tallje",
  "topthi",
  "puqem",
  "katër",
  "bufit",
  "omega",
  "gjumash",
  "bejkë",
  "indoj",
  "netër",
  "princ",
  "jatak",
  "endëz",
  "lënur",
  "qërim",
  "kuqon",
  "furde",
  "kaçerr",
  "shtirë",
  "çmbush",
  "garzë",
  "kulak",
  "shpues",
  "rimon",
  "bonjak",
  "epror",
  "shtalp",
  "radio",
  "bishor",
  "farsë",
  "vijash",
  "stoli",
  "sikur",
  "ujezë",
  "holloj",
  "okare",
  "piano",
  "soval",
  "hedhje",
  "targë",
  "ballës",
  "pohor",
  "arazi",
  "motor",
  "farje",
  "zijos",
  "qyrek",
  "bymim",
  "gugash",
  "kollan",
  "shtrig",
  "çmërs",
  "përul",
  "murrak",
  "gëzof",
  "hutin",
  "shikoj",
  "zhguall",
  "mitër",
  "pular",
  "vizoj",
  "mësysh",
  "kastë",
  "fjollë",
  "pëlyr",
  "kulaç",
  "shtrij",
  "sharës",
  "njomje",
  "lëmes",
  "leckë",
  "revan",
  "llapar",
  "roman",
  "cifël",
  "grafi",
  "motër",
  "blatë",
  "mujshi",
  "nallçë",
  "mekje",
  "pjekë",
  "gjatosh",
  "zhytës",
  "karar",
  "minim",
  "vakët",
  "kabëz",
  "kofshë",
  "minoj",
  "kreko",
  "vapem",
  "varros",
  "shkurt",
  "brimë",
  "parem",
  "shtiak",
  "rreban",
  "çakthi",
  "thanak",
  "kabot",
  "xëgim",
  "thatim",
  "ranor",
  "pjalm",
  "kaptë",
  "gërnjë",
  "sherret",
  "dihet",
  "kosar",
  "voltë",
  "halës",
  "ushtoj",
  "topuz",
  "keqas",
  "veshkë",
  "qethar",
  "tekdo",
  "nurzi",
  "gënjim",
  "latin",
  "katac",
  "shoshur",
  "binjak",
  "bluar",
  "nusël",
  "rrogoj",
  "zhukar",
  "shkordh",
  "sqapth",
  "aktor",
  "qimosh",
  "vijos",
  "rrëgjoj",
  "rropak",
  "mashan",
  "karrem",
  "përse",
  "qarrës",
  "tyxhar",
  "zhapem",
  "bunar",
  "gjithnjë",
  "pasiv",
  "trevë",
  "pezull",
  "mblat",
  "biskë",
  "theror",
  "gjunjas",
  "durim",
  "sharroj",
  "rrënjak",
  "datim",
  "plëng",
  "dhogtë",
  "cipëz",
  "bakër",
  "burmë",
  "zihem",
  "valloj",
  "milor",
  "barik",
  "gojas",
  "alibi",
  "dorëz",
  "gërxho",
  "citoj",
  "masazh",
  "amësht",
  "abresh",
  "curroj",
  "ndejë",
  "shëroj",
  "veshje",
  "rrethim",
  "cumër",
  "lafshë",
  "shator",
  "glepë",
  "agshol",
  "mbroth",
  "kyçem",
  "pungë",
  "shogur",
  "mjeri",
  "bijoj",
  "bijor",
  "shoshje",
  "biule",
  "qepur",
  "hamall",
  "reshme",
  "shavar",
  "trinë",
  "ajrim",
  "bllacë",
  "katoq",
  "llopje",
  "arian",
  "topaz",
  "rrethoj",
  "gjinor",
  "busull",
  "saktë",
  "çakël",
  "tejzë",
  "mpitë",
  "prinxh",
  "krecë",
  "thinjur",
  "shajkë",
  "mollash",
  "zhdryj",
  "kunjëz",
  "denjoj",
  "bukas",
  "dhashkë",
  "pallmë",
  "grisë",
  "bramc",
  "ngecë",
  "bardhë",
  "sajnë",
  "flugë",
  "dergjë",
  "ndyrë",
  "fyçkë",
  "drojë",
  "bekçi",
  "picas",
  "ushton",
  "bulbe",
  "kuqem",
  "tepsi",
  "kapëz",
  "ortak",
  "kutull",
  "cermë",
  "bartë",
  "sheshmë",
  "bligje",
  "fillor",
  "sylesh",
  "cohtë",
  "haber",
  "zebër",
  "fugim",
  "shkupë",
  "yjësi",
  "ballas",
  "balliç",
  "ftesë",
  "endem",
  "tullac",
  "pilaf",
  "skape",
  "zatet",
  "trust",
  "mbase",
  "rripoz",
  "vajos",
  "murim",
  "binar",
  "kuqar",
  "hikërr",
  "sosje",
  "cylek",
  "qimëz",
  "çmend",
  "ithtar",
  "shatoj",
  "fikës",
  "rrenës",
  "petëz",
  "buxhak",
  "shtegth",
  "askush",
  "ndezë",
  "lumak",
  "qepar",
  "çirrem",
  "rikan",
  "qiqër",
  "tyrbe",
  "osakë",
  "qepen",
  "cajkë",
  "hajat",
  "kotull",
  "maçok",
  "qëroj",
  "zhajkë",
  "gunëz",
  "pikat",
  "qiriç",
  "irnim",
  "gaberr",
  "manto",
  "shupkë",
  "lumthi",
  "virrmë",
  "famull",
  "homazh",
  "tymak",
  "sharje",
  "bonjar",
  "njohur",
  "grihë",
  "gaxhorr",
  "nishan",
  "shtatë",
  "ushinth",
  "thithur",
  "qetem",
  "trivë",
  "punoj",
  "kjomë",
  "situr",
  "urenjë",
  "rrekëz",
  "qoset",
  "rysni",
  "bitum",
  "rrënjor",
  "veçim",
  "hithkë",
  "drudhë",
  "zdrug",
  "gjiton",
  "barok",
  "ideal",
  "rajon",
  "artor",
  "vizoj",
  "gradë",
  "baltë",
  "polak",
  "turne",
  "pandë",
  "blues",
  "laroj",
  "njohës",
  "qengjë",
  "hequr",
  "xhixhël",
  "lagët",
  "poezi",
  "bëshëm",
  "kurim",
  "rimtë",
  "fyrlë",
  "robot",
  "bobëz",
  "barut",
  "lihem",
  "pikël",
  "dëkim",
  "mjelm",
  "hidhem",
  "kuvli",
  "qokël",
  "shties",
  "kushdo",
  "hoshaf",
  "ngado",
  "verdhë",
  "marrash",
  "çikul",
  "kumak",
  "furkë",
  "normë",
  "vasal",
  "gapërr",
  "falur",
  "delesh",
  "plotë",
  "naftë",
  "çallmë",
  "kurdo",
  "teket",
  "fibër",
  "zhbërë",
  "ndrys",
  "guazë",
  "thelmë",
  "kasap",
  "dërti",
  "kërmë",
  "qasem",
  "cipull",
  "laper",
  "lajthi",
  "gjetiu",
  "mëzat",
  "kaposh",
  "gjëmim",
  "stuko",
  "maskë",
  "zanon",
  "pikur",
  "mohës",
  "tymtë",
  "llapës",
  "cërron",
  "penjëz",
  "kapem",
  "athëti",
  "ganxhë",
  "morrit",
  "komçë",
  "shkrap",
  "llockë",
  "zhabil",
  "cabik",
  "rehat",
  "zvarg",
  "gypor",
  "shpuar",
  "anuar",
  "fashis",
  "gonxhe",
  "dhjetë",
  "sifon",
  "damlla",
  "djalle",
  "bankë",
  "lidhem",
  "qasët",
  "takoj",
  "motiv",
  "shijor",
  "qeser",
  "bukël",
  "topit",
  "kilzë",
  "shtirë",
  "pasiv",
  "ballcë",
  "rezhgë",
  "mezhdë",
  "thinjem",
  "rrebesh",
  "djerrë",
  "shulët",
  "mbarë",
  "dukur",
  "cedër",
  "hirrëz",
  "torno",
  "prapë",
  "çorbë",
  "zhbart",
  "fucoj",
  "rrekje",
  "atlas",
  "pushtë",
  "pirat",
  "oklim",
  "qajkë",
  "nunër",
  "luhat",
  "ardhur",
  "bishar",
  "ajroj",
  "dejkë",
  "larti",
  "petak",
  "pasoj",
  "vaket",
  "mëkeq",
  "vëllim",
  "qylyk",
  "heqës",
  "zdraj",
  "gollak",
  "aktiv",
  "gjetje",
  "xhiroj",
  "buçet",
  "gënjej",
  "vocërr",
  "dyzim",
  "bibish",
  "rubël",
  "guxhas",
  "zgjedhë",
  "zabit",
  "retush",
  "zbokth",
  "shlizë",
  "kanos",
  "shtang",
  "bythkë",
  "leshur",
  "nuskë",
  "uratë",
  "nanuq",
  "kabash",
  "zgjonjë",
  "arnim",
  "miopi",
  "treshe",
  "zëmadh",
  "arnoj",
  "vigmë",
  "qurros",
  "krimb",
  "krënd",
  "direk",
  "velet",
  "rritur",
  "thikar",
  "terem",
  "zulmë",
  "roshan",
  "buzas",
  "shtatë",
  "pjerrk",
  "pelin",
  "imtoj",
  "kuqur",
  "aromë",
  "qethës",
  "shtojë",
  "çirak",
  "zbres",
  "alltën",
  "rosto",
  "mutaf",
  "rrëshen",
  "bundë",
  "hesap",
  "nuhar",
  "rishes",
  "gurrni",
  "dylltë",
  "esnaf",
  "qellem",
  "strall",
  "rysur",
  "lendi",
  "masiv",
  "byrde",
  "paudhë",
  "lidhëz",
  "psalm",
  "koral",
  "çakall",
  "rrebull",
  "gocan",
  "ballor",
  "veshok",
  "dredhe",
  "frang",
  "vegël",
  "osmak",
  "cipal",
  "parti",
  "kësaç",
  "cipje",
  "mardha",
  "zilar",
  "sqotë",
  "ladut",
  "plesht",
  "dosje",
  "tortë",
  "efshtë",
  "gorar",
  "pufkë",
  "barkth",
  "vajës",
  "shukje",
  "dhjetë",
  "nuskë",
  "vënie",
  "gjumëz",
  "thuhet",
  "zhanër",
  "nejës",
  "shytim",
  "pikoj",
  "kopsht",
  "nusëz",
  "bizon",
  "shojzë",
  "afrim",
  "bokërr",
  "shatorr",
  "qibër",
  "derrar",
  "armen",
  "fijoj",
  "belot",
  "bufon",
  "mizor",
  "demon",
  "guxit",
  "ngrys",
  "cikem",
  "dënoj",
  "gjethis",
  "shpejt",
  "bogaz",
  "shqotë",
  "pelte",
  "bllore",
  "shturë",
  "vring",
  "sokol",
  "nartë",
  "urekth",
  "shkuar",
  "shifër",
  "cofët",
  "ageshë",
  "ushqim",
  "ngërth",
  "cirlë",
  "thepor",
  "pagan",
  "shpuzë",
  "teinë",
  "avore",
  "rubin",
  "sutak",
  "kithët",
  "trunt",
  "arian",
  "ajdës",
  "bashkë",
  "shaloj",
  "kreçe",
  "rrokje",
  "zakon",
  "shqeme",
  "zhaban",
  "shparg",
  "qirith",
  "pelush",
  "kudhër",
  "kumac",
  "bullut",
  "llapoj",
  "vragë",
  "çehre",
  "kruaj",
  "folje",
  "cëpëz",
  "rrilkë",
  "qurroj",
  "gjethor",
  "pamor",
  "bashkë",
  "beshte",
  "grepç",
  "barkë",
  "xhezve",
  "këngë",
  "kollaj",
  "pehar",
  "veshtë",
  "lërej",
  "vapët",
  "bimor",
  "kokërr",
  "çinoj",
  "çiket",
  "gribë",
  "rrudhur",
  "vluar",
  "gjethëz",
  "hakçe",
  "turbë",
  "njëzet",
  "furçë",
  "milcë",
  "lulim",
  "njëzoj",
  "listë",
  "kolit",
  "guhat",
  "shtatë",
  "shirës",
  "kromë",
  "banor",
  "shkulë",
  "silur",
  "balosh",
  "sapun",
  "lypje",
  "shushat",
  "avrom",
  "njëfish",
  "gajës",
  "larash",
  "harpë",
  "virus",
  "zavëz",
  "hakoj",
  "pendë",
  "borde",
  "faunë",
  "vapoj",
  "shterë",
  "zhytas",
  "ngjyrë",
  "lukër",
  "shtagë",
  "çamtë",
  "major",
  "cubak",
  "arape",
  "shëmim",
  "mbret",
  "metër",
  "luzmë",
  "shehër",
  "namaz",
  "kazil",
  "lonxho",
  "mëshoj",
  "njohës",
  "jesir",
  "kokët",
  "verrle",
  "rrënoj",
  "shkrep",
  "shutra",
  "oreks",
  "harrës",
  "baron",
  "lator",
  "herës",
  "buzel",
  "kinez",
  "elmaz",
  "cofët",
  "agjent",
  "shogët",
  "arbër",
  "nivel",
  "tezak",
  "garbë",
  "qyngje",
  "qipull",
  "gaboj",
  "shapkë",
  "thimash",
  "rishte",
  "ovore",
  "halor",
  "makar",
  "futur",
  "bythçe",
  "spinë",
  "prapi",
  "kazan",
  "bujar",
  "trysa",
  "gocar",
  "shënjoj",
  "zhytem",
  "lojbë",
  "zaman",
  "spilc",
  "rrufkë",
  "paudha",
  "vidër",
  "shtërg",
  "ergjëz",
  "këlliç",
  "borës",
  "ranor",
  "qarash",
  "rufai",
  "shinon",
  "blerë",
  "mëtoj",
  "autor",
  "ficet",
  "mpiks",
  "silos",
  "ankoj",
  "cylaq",
  "kobit",
  "qullje",
  "zezak",
  "qytet",
  "pozoj",
  "gurthi",
  "moçoj",
  "pasiv",
  "bilan",
  "fushnji",
  "buzoç",
  "balluk",
  "pagjak",
  "gazim",
  "vajor",
  "verzë",
  "kunjim",
  "buqar",
  "nejçë",
  "cenak",
  "saraf",
  "tetor",
  "lugës",
  "dijës",
  "nozull",
  "gjakëz",
  "qepëz",
  "kovaç",
  "osman",
  "kllashë",
  "cuckë",
  "navllo",
  "limon",
  "mbathë",
  "llafos",
  "njomur",
  "kapuç",
  "humbë",
  "belik",
  "makut",
  "lajle",
  "sharkë",
  "fortë",
  "sharov",
  "veshëz",
  "rroboj",
  "reshje",
  "eston",
  "kaçkë",
  "thuret",
  "kallfë",
  "fushoj",
  "klishe",
  "timon",
  "fishek",
  "pizgë",
  "anodë",
  "grisë",
  "rrëgjak",
  "mellan",
  "buçko",
  "çelur",
  "lëthur",
  "rritem",
  "hallkë",
  "shëshas",
  "strup",
  "lajkë",
  "buxhet",
  "varur",
  "zbardh",
  "klasë",
  "shular",
  "lakat",
  "amullt",
  "lugor",
  "tallem",
  "galop",
  "mërshë",
  "limim",
  "kuvar",
  "mishnjë",
  "sharrok",
  "shitim",
  "aleat",
  "truaj",
  "dhentë",
  "çifut",
  "martë",
  "dakull",
  "lidhak",
  "qylaf",
  "pashkë",
  "shkuar",
  "quhem",
  "lashtë",
  "jaran",
  "trashë",
  "shpatë",
  "gjurmë",
  "shushuq",
  "bamje",
  "thyesë",
  "frulli",
  "neqez",
  "shirok",
  "bollka",
  "etikë",
  "qullac",
  "pupëz",
  "amton",
  "ermik",
  "porsi",
  "cingth",
  "ngreh",
  "takim",
  "theloj",
  "lakës",
  "hapje",
  "lakër",
  "ndryp",
  "misër",
  "kobar",
  "asqer",
  "thepje",
  "paqmë",
  "shkues",
  "larmë",
  "peckë",
  "kafaz",
  "sharki",
  "gërraç",
  "veçse",
  "mahit",
  "qepës",
  "gojëz",
  "kuqal",
  "gjymysh",
  "erëza",
  "qeskë",
  "shytoj",
  "qëllim",
  "mekem",
  "çelem",
  "dangë",
  "hollim",
  "tharet",
  "gojaç",
  "fustë",
  "gliqe",
  "qorras",
  "qafuk",
  "saçmë",
  "gerrlë",
  "blidë",
  "pirash",
  "njehem",
  "laçkë",
  "njomem",
  "shtrat",
  "qepor",
  "kallni",
  "sheshim",
  "metoq",
  "ndjell",
  "buzak",
  "larua",
  "molit",
  "çapem",
  "sefte",
  "allaxha",
  "njehje",
  "pjekë",
  "madhoj",
  "plebe",
  "zhuris",
  "trofe",
  "hullim",
  "cinkë",
  "rumun",
  "shofer",
  "mulat",
  "mbledh",
  "pihem",
  "regjje",
  "ëndet",
  "lëkoq",
  "majuc",
  "lëbyr",
  "kërrmi",
  "uloke",
  "tufës",
  "hetoj",
  "mesëm",
  "darkë",
  "lymtë",
  "misur",
  "dhunti",
  "zymtë",
  "tavan",
  "çilash",
  "thantë",
  "gjatim",
  "ciqër",
  "mbarë",
  "fqinjë",
  "sërish",
  "kllapë",
  "shlligë",
  "merrem",
  "torua",
  "tëngë",
  "gemtë",
  "gushëz",
  "skllav",
  "thëllim",
  "hejbe",
  "robti",
  "pahir",
  "dukem",
  "mbasi",
  "anion",
  "tubim",
  "romak",
  "ndishk",
  "vrevë",
  "gugon",
  "jazëk",
  "saraj",
  "bukël",
  "çalloj",
  "krocë",
  "mashkë",
  "ëmbël",
  "majmë",
  "bythec",
  "sanëz",
  "bitërr",
  "dyllor",
  "endës",
  "çarki",
  "heshtë",
  "teori",
  "shalim",
  "skelë",
  "stenë",
  "hokoj",
  "andri",
  "çefkë",
  "çekiç",
  "thashëm",
  "krifë",
  "paanë",
  "zhdredh",
  "azdis",
  "kështu",
  "gjepur",
  "synet",
  "urtar",
  "guror",
  "lëkua",
  "ricin",
  "erore",
  "shkalë",
  "prapë",
  "shkuar",
  "bakëm",
  "klitë",
  "pallto",
  "llëngë",
  "hafëz",
  "syfyr",
  "shqitë",
  "skedë",
  "gruar",
  "amebë",
  "thepis",
  "shajni",
  "çamër",
  "ndryj",
  "bocun",
  "feçkë",
  "tuaja",
  "njësim",
  "vraçi",
  "barros",
  "fajde",
  "faror",
  "vathim",
  "mykem",
  "okllai",
  "njohur",
  "çalim",
  "lëmak",
  "zotoj",
  "bakthi",
  "janki",
  "paksa",
  "kakao",
  "cilik",
  "piton",
  "rrokje",
  "terror",
  "gracë",
  "arushë",
  "axhami",
  "dramë",
  "cokat",
  "symadh",
  "bungë",
  "rrahës",
  "qenie",
  "fazan",
  "lidhës",
  "pallosh",
  "beftë",
  "qafim",
  "telish",
  "peshor",
  "ashpër",
  "butak",
  "mullar",
  "pllajë",
  "kyçur",
  "bejte",
  "thartë",
  "kësen",
  "therem",
  "rërët",
  "magjik",
  "evgjit",
  "shtang",
  "rigon",
  "dynym",
  "hapës",
  "hedhës",
  "dardhë",
  "tharës",
  "fatal",
  "dollar",
  "hallat",
  "grilë",
  "efshoj",
  "zhokël",
  "pajim",
  "gufoj",
  "qyqoj",
  "shurdhi",
  "mëkim",
  "krodhë",
  "belbe",
  "argas",
  "rusem",
  "gjetur",
  "shqyej",
  "arithe",
  "kopje",
  "falje",
  "shtrydh",
  "matuf",
  "gjeroj",
  "sinus",
  "ngordh",
  "kumash",
  "akrep",
  "rrahës",
  "qullash",
  "fodull",
  "jugor",
  "kallam",
  "tiran",
  "blinth",
  "tekst",
  "galiç",
  "lehur",
  "oktet",
  "qesat",
  "ijazi",
  "zukat",
  "vallez",
  "vigan",
  "ajror",
  "gdhirë",
  "operë",
  "bulkth",
  "gremë",
  "mbrus",
  "gjerbë",
  "mafër",
  "syrrush",
  "shebek",
  "hapës",
  "llakim",
  "pruaj",
  "viçok",
  "tumir",
  "kofin",
  "saldo",
  "yshtur",
  "dibël",
  "varroj",
  "mugëll",
  "katet",
  "gufër",
  "hanak",
  "serum",
  "leton",
  "hejzë",
  "roman",
  "rrezik",
  "maraq",
  "armoj",
  "dungë",
  "gushal",
  "qëkur",
  "lesoj",
  "ideor",
  "përqi",
  "fikem",
  "civil",
  "zakum",
  "bezgë",
  "mohaq",
  "çikët",
  "binjak",
  "syzoj",
  "endës",
  "godit",
  "mëlçi",
  "mason",
  "aspak",
  "diodë",
  "perde",
  "haraç",
  "litër",
  "bryme",
  "akord",
  "ajror",
  "fjeta",
  "sfrat",
  "bukës",
  "patok",
  "shugat",
  "ujshëm",
  "shtënë",
  "bretk",
  "kapit",
  "vajoj",
  "arsye",
  "qepoj",
  "gjobim",
  "zgale",
  "gjoboj",
  "pikët",
  "shprish",
  "mitër",
  "ekran",
  "qulloj",
  "syllit",
  "selvi",
  "lodhës",
  "dallim",
  "çelik",
  "gashtë",
  "parur",
  "brushë",
  "ardhur",
  "avari",
  "stekë",
  "maçkë",
  "batall",
  "ngjasi",
  "qepje",
  "pësim",
  "balun",
  "begat",
  "tutkë",
  "qeshur",
  "shajak",
  "kanat",
  "sharrak",
  "furrik",
  "pakta",
  "heqës",
  "susak",
  "njëjtë",
  "konak",
  "lartë",
  "thadër",
  "dredhë",
  "murrmë",
  "shtënë",
  "koçan",
  "hasëll",
  "ngajë",
  "shtrep",
  "zbraz",
  "larëz",
  "shpinë",
  "rrymon",
  "shtruf",
  "matur",
  "fikës",
  "gjethet",
  "larash",
  "fytak",
  "cekët",
  "dekan",
  "ninëz",
  "bimëz",
  "liker",
  "shetkë",
  "satër",
  "shitës",
  "pakoj",
  "islam",
  "dozim",
  "kufkë",
  "guros",
  "ditor",
  "dhjetë",
  "rangë",
  "dhunim",
  "radhua",
  "qorrthi",
  "kolos",
  "vokal",
  "kllapi",
  "thatik",
  "shkallë",
  "shëmti",
  "buzëz",
  "dobiç",
  "paana",
  "frazë",
  "gajle",
  "gungë",
  "midis",
  "lalush",
  "gardhë",
  "vegoj",
  "gorgë",
  "cekim",
  "piqem",
  "qeshem",
  "verzë",
  "natar",
  "nakar",
  "kuqël",
  "llashkë",
  "pejan",
  "fletë",
  "mbret",
  "lëshoj",
  "kokosh",
  "sheshje",
  "kopil",
  "iridë",
  "fillak",
  "kërce",
  "rrëfej",
  "shumoj",
  "shmang",
  "batim",
  "zotim",
  "guhak",
  "grind",
  "çapar",
  "boshje",
  "lagush",
  "guhak",
  "qullur",
  "pjesë",
  "zënie",
  "sorrëz",
  "bitme",
  "afroj",
  "rival",
  "hamje",
  "pezëm",
  "padit",
  "cekoj",
  "plojë",
  "currak",
  "limer",
  "zavall",
  "qatje",
  "nëntë",
  "marruk",
  "aftër",
  "ndodhi",
  "gërrec",
];