import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Si të luani?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Qëllo fjalën me gjashtë përpjekje. Pas secilës përpjekje fjala do të ngjyroset me ngjyra që tregojnë cfarë ka të saktë rreth fjalës që keni zgjedhur.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="D"
          status="correct"
        />
        <Cell value="I" isCompleted={true} />
        <Cell value="M" isCompleted={true} />
        <Cell value="Ë" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Shkronja D është pjesë e fjalës, poashtu është në vend të saktë.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="Ç" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="K"
          status="present"
        />
        <Cell value="I" isCompleted={true} />
        <Cell value="Ç" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Shkronja K është pjesë e fjalës por është në vend të gabuar.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="TH" isCompleted={true} />
        <Cell value="I" isCompleted={true} />
        <Cell value="RR" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="M" status="absent" />
        <Cell value="Ë" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Shkronja M nuk është pjesë e fjalës.
      </p>
    </BaseModal>
  )
}
